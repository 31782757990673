import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";

import Tooltip from "../../Tooltip.js";
import Popup from "../../Popup.js";
import { useSnackbar } from "../../../utils/index.js";
import Form from "../../Form.js";
import { ErrorBackgroundButton } from "../../Buttons.js";
import { Grid } from "@mui/material";

const toTitleCase = (phrase) => (phrase
	.toLowerCase()
	.split(" ")
	.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
	.join(" ")
);

const DoubleItem = ({
	mode = "view",
	id = "",
	name: propsName = "",
	initialName = "",
	position1: propsPosition1 = { x: 0, y: 0 },
	position2: propsPosition2 = { x: 0, y: 0 },
	icons: propsIcons = {},
	variables: propsVariables = {},
	elementDimensions: propsElementDimensions = 30,
	mapWidth: propsMapWidth = 100,
	mapHeight: propsMapHeight = 80,
	resolution: propsResolution = 0.1,
	cat = "",
	itemCat = "",
	updateItem: propsUpdateItem = () => {},
	deleteItem: propsDeleteItem = () => {},
	checkNameExists: propsCheckNameExists = () => {},
}) => {
	const [name, setName] = useState(propsName);
	const [position1, setPosition1] = useState(propsPosition1);
	const [position2, setPosition2] = useState(propsPosition2);
	const [variables, setVariables] = useState(propsVariables);
	const [elementDimensions, setElementDimensions] = useState(propsElementDimensions);

	const [mapWidth, setMapWidth] = useState(propsMapWidth);
	const [mapHeight, setMapHeight] = useState(propsMapHeight);
	const [resolution, setResolution] = useState(propsResolution);

	const [dragging1, setDragging1] = useState(false);
	const [dragging2, setDragging2] = useState(false);
	const [popupOpen, setPopupOpen] = useState(false);
	const [metersTooltip1Open, setMetersTooltip1Open] = useState(false);
	const [metersTooltip2Open, setMetersTooltip2Open] = useState(false);
	const [metersTooltipPosition, setMetersTooltipPosition] = useState({ x: 0, y: 0 });
	const [infoTooltip1Open, setInfoTooltip1Open] = useState(false);
	const [infoTooltip2Open, setInfoTooltip2Open] = useState(false);
	const [meters1Display, setMeters1Display] = useState(false);
	const [meters2Display, setMeters2Display] = useState(false);
	const [tmpFormValues, setTmpFormValues] = useState(Object.fromEntries(Object.keys(variables)
		.map((variable) => [variable, variables[variable].value])));
	const [pixelsDimensions, setPixelsDimensions] = useState({ x1: 0, y1: 0, x2: 0, y2: 0 });

	const isHuman = itemCat === "human";
	const isColor = itemCat === "color";
	const isWall = itemCat === "wall";

	const { error } = useSnackbar();

	const sendUpdate = (newValues) => {
		const tmpState = {
			id,
			itemName: name,
			x1: position1.x,
			y1: position1.y,
			x2: position2.x,
			y2: position2.y,
			variables,
			cat,
			itemCat,
		};

		for (const [key, value] of Object.entries(newValues)) {
			tmpState[key] = value;
		}

		propsUpdateItem(cat, itemCat, id, tmpState);
	};

	const onClick = () => {
		setPopupOpen(true);
	};

	const on1Start = (event) => {
		event.preventDefault();
		const xMeters = (((position1.x / 100) * mapWidth) * resolution).toFixed(2);
		const yMeters = (((position1.y / 100) * mapHeight) * resolution).toFixed(2);
		setMetersTooltip1Open(true);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setInfoTooltip1Open(false);
	};

	const on1Drag = (event, data) => {
		event.preventDefault();
		const xMeters = ((((position1.x / 100) * mapWidth) + data.x) * resolution).toFixed(2);
		const yMeters = ((((position1.y / 100) * mapHeight) - data.y) * resolution).toFixed(2);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setDragging1(true);
	};

	const on1Stop = (event, data) => {
		event.preventDefault();
		if (dragging1) {
			const newX = Math.max(Math.min(((((position1.x / 100) * mapWidth) + data.x) / mapWidth) * 100, 100), 0);
			const newY = Math.max(Math.min((((((position1.y / 100) * mapHeight) - data.y) / mapHeight) * 100), 100), 0);
			sendUpdate({ x1: newX, y1: newY });
		} else {
			onClick();
		}

		setDragging1(false);
		setMetersTooltip1Open(false);
	};

	const on2Start = (event) => {
		event.preventDefault();
		const xMeters = (((position2.x / 100) * mapWidth) * resolution).toFixed(2);
		const yMeters = (((position2.y / 100) * mapHeight) * resolution).toFixed(2);
		setMetersTooltip2Open(true);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setInfoTooltip2Open(false);
	};

	const on2Drag = (event, data) => {
		event.preventDefault();
		const xMeters = ((((position2.x / 100) * mapWidth) + data.x) * resolution).toFixed(2);
		const yMeters = ((((position2.y / 100) * mapHeight) - data.y) * resolution).toFixed(2);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setDragging2(true);
	};

	const on2Stop = (event, data) => {
		event.preventDefault();
		if (dragging2) {
			const newX = Math.max(Math.min(((((position2.x / 100) * mapWidth) + data.x) / mapWidth) * 100, 100), 0);
			const newY = Math.max(Math.min((((((position2.y / 100) * mapHeight) - data.y) / mapHeight) * 100), 100), 0);
			sendUpdate({ x2: newX, y2: newY });
		} else {
			onClick();
		}

		setDragging2(false);
		setMetersTooltip2Open(false);
	};

	const deleteItem = () => {
		propsDeleteItem(cat, itemCat, id);
	};

	const checkNameExists = (value) => {
		const { matchesPattern, found } = propsCheckNameExists(value, initialName, cat, itemCat, id);

		if (matchesPattern) {
			error("Name cannot follow the template category_number");
		} else if (found) {
			error("Name already exists");
		}

		return matchesPattern || found;
	};

	const onSettingsSubmit = (values) => {
		const { itemName, ...newValues } = values;

		if (itemName !== name && checkNameExists(itemName)) {
			return;
		}

		const newVariables = { ...variables };
		for (const [key, value] of Object.entries(newValues)) {
			newVariables[key].value = value;
		}

		sendUpdate({ variables: newVariables, itemName: itemName.toLowerCase().split(" ").join("_") });
		setPopupOpen(false);
	};

	const closePopup = () => {
		setPopupOpen(false);
		setTmpFormValues(Object.fromEntries(Object.keys(variables)
			.map((variable) => [variable, variables[variable].value])));
	};

	const formContent = useMemo(() => {
		const content = [{
			customType: "input",
			id: "itemName",
			type: "text",
			label: "Name",
			placeholder: "Name",
			value: name,
			onChange: (event) => {
				checkNameExists(event.target.value);
				setTmpFormValues({ ...tmpFormValues, itemName: event.target.value });
			},
		}];

		if (!isColor) {
			for (const [variable, options] of Object.entries(variables)) {
				const {
					type,
					placeholder,
					min,
					max,
					step,
					precision,
					format,
					options: selectItems,
					needs,
				} = options;
				if (needs) {
					let needsMet = true;
					for (const neededKey of Object.keys(needs)) {
						const neededValue = needs[neededKey];
						if (tmpFormValues?.[neededKey] !== undefined) {
							let found = false;
							for (const val of neededValue) {
								if (tmpFormValues[neededKey] === val) {
									found = true;
									break;
								}
							}

							needsMet = found;
							if (!needsMet) {
								break;
							}
						}
					}

					if (!needsMet) {
						// eslint-disable-next-line no-continue
						continue;
					}
				}

				switch (type) {
					case "text": {
						content.push({
							customType: "input",
							id: variable,
							type: "text",
							label: toTitleCase(variable),
							placeholder,
							value: tmpFormValues[variable],
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
							},
						});

						break;
					}

					case "number": {
						content.push({
							customType: "number",
							id: variable,
							type: "number",
							label: toTitleCase(variable),
							value: variables[variable].value,
							min: (typeof min === "string" ? tmpFormValues?.[min] : min),
							max: (typeof max === "string" ? tmpFormValues?.[max] : max),
							step,
							precision,
							...(format ? {
								format: (num) => `${num}${format}`,
							} : {}),
							...(format ? {
								parse: (num) => num.replace(format, ""),
							} : {}),
							onChange: (val) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: val });
							},
						});

						break;
					}

					case "boolean": {
						content.push({
							customType: "checkbox",
							id: variable,
							label: toTitleCase(variable),
							defaultValue: tmpFormValues[variable],
							color: "secondary",
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.checked });
							},
						});

						break;
					}

					case "select": {
						content.push({
							customType: "dropdown",
							id: variable,
							label: toTitleCase(variable),
							defaultValue: tmpFormValues[variable],
							items: selectItems.map((item) => ({ value: item, text: item })),
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
							},
						});

						break;
					}

					case "switch": {
						content.push({
							customType: "switch",
							id: variable,
							label: toTitleCase(variable),
							defaultValue: tmpFormValues[variable],
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.checked });
							},
						});

						break;
					}

					default:
				// Do nothing
				}
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, variables, tmpFormValues]);

	useEffect(() => {
		sendUpdate({});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMapWidth(propsMapWidth);
	}, [propsMapWidth]);

	useEffect(() => {
		setMapHeight(propsMapHeight);
	}, [propsMapHeight]);

	useEffect(() => {
		if (propsPosition1.x !== position1.x || propsPosition1.y !== position1.y) {
			setPosition1(propsPosition1);
		}
	}, [position1.x, position1.y, propsPosition1]);

	useEffect(() => {
		if (propsPosition2.x !== position2.x || propsPosition2.y !== position2.y) {
			setPosition2(propsPosition2);
		}
	}, [position2.x, position2.y, propsPosition2]);

	useEffect(() => {
		setVariables(propsVariables);
	}, [propsVariables]);

	useEffect(() => {
		setName(propsName);
	}, [propsName]);

	useEffect(() => {
		setResolution(propsResolution);
	}, [propsResolution]);

	useEffect(() => {
		setElementDimensions(propsElementDimensions);
	}, [propsElementDimensions]);

	useEffect(() => {
		setPixelsDimensions({
			x1: dragging1
				? metersTooltipPosition.x / resolution
				: (position1.x / 100) * mapWidth,
			y1: dragging1
				? metersTooltipPosition.y / resolution
				: (position1.y / 100) * mapHeight,
			x2: dragging2
				? metersTooltipPosition.x / resolution
				: (position2.x / 100) * mapWidth,
			y2: dragging2
				? metersTooltipPosition.y / resolution
				: (position2.y / 100) * mapHeight,
		});
	}, [position1, position2, mapWidth, mapHeight, dragging1, dragging2, metersTooltipPosition, resolution]);

	return ([
		<div
			key={`line_${id}`}
			style={{
				width: `${Math.sqrt(((pixelsDimensions.x2 - pixelsDimensions.x1) ** 2) + ((pixelsDimensions.y2 - pixelsDimensions.y1) ** 2))}px`,
				height: "2px",
				background: isWall
					? "#262835"
					: (cat === "general"
						? "rgba(19, 78, 218, 0.6)"
						: (cat === "effectors"
							? "rgba(250, 160, 0, 0.6)"
							: "rgba(255, 0, 0, 0.6)"
						)),
				transform: `rotate(${-1 * ((Math.atan2(pixelsDimensions.y2 - pixelsDimensions.y1, pixelsDimensions.x2 - pixelsDimensions.x1) * 180) / Math.PI)}deg)`,
				transformOrigin: "left center",
				position: "absolute",
				left: `${pixelsDimensions.x1}px`,
				bottom: `${pixelsDimensions.y1 - 1}px`,
				zIndex: 0,
			}}
		/>,
		<Draggable
			key={`item1_${id}`}
			allowAnyClick={false}
			{... (mode === "edit" ? { grid: [5, 5] } : {})}
			zIndex={100}
			position={{ x: 0, y: 0 }}
			disabled={mode === "view"}
			onStart={mode === "edit" ? on1Start : () => {}}
			onDrag={mode === "edit" ? on1Drag : () => {}}
			onStop={mode === "edit" ? on1Stop : () => {}}
		>

			<div
				id={`item1_${id}`}
				style={{
					width: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					height: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					borderRadius: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					position: "absolute",
					left: `calc(${position1.x}% - ${(((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions) / 2}px)`,
					bottom: `calc(${position1.y}% - ${(((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions) / 2}px)`,
					border: isColor ? "2px solid #134eda" : "none",
					margin: (isHuman || isColor) ? "6px" : "0px",
					padding: (isHuman || isColor) ? "5px" : "0px",
					boxShadow: (isHuman || isColor) ? "0px 0px 20px 5px rgba(0, 0, 0, 0.5)" : "none",
					background: isHuman
						? "#134eda"
						: "transparent",
					cursor: mode === "view"
						? "help"
						: dragging1 ? "move" : "pointer",
					transform: "translate(-50%, -50%)",
				}}
			>
				<Tooltip
					open={infoTooltip1Open}
					handleOpen={() => {
						if (dragging1 || dragging2 || popupOpen) {
							return;
						}

						if (Object.keys(variables).filter((variable) => variables[variable].tooltipDisplay).length === 0) {
							return;
						}

						setInfoTooltip1Open(true);
					}}
					handleClose={() => setInfoTooltip1Open(false)}
					title={(
						<>
							<div
								key="itemTooltip_name"
								style={{
									display: "flex",
									flexDirection: "column",
									textAlign: "center",
									width: "150px",
									borderRadius: "10px",
									color: "white",
								}}
							>
								<div style={{ fontWeight: "bold", fontSize: "16px" }}>
									{name}
								</div>
							</div>
							{Object.keys(variables)
								.filter((variable) => variables[variable].tooltipDisplay)
								.map((variable) => (
									<div
										key={`itemTooltip_${variable}`}
										style={{
											display: "flex",
											flexDirection: "column",
											textAlign: "center",
											width: "150px",
											padding: "5px",
											borderRadius: "10px",
											color: "white",
										}}
									>
										<div style={{ fontWeight: "bold", fontSize: "16px" }}>
											{toTitleCase(variable)}
										</div>
										<div
											style={{
												height: "fit-content",
												wordBreak: "break-word",
												fontSize: "14px",
											}}
										>
											{
												["switch", "boolean"].includes(variables[variable].type)
													? (variables[variable].value ? "On" : "Off")
													: variables[variable].value || "-"
											}
										</div>
									</div>
								))}
						</>
					)}
				>
					<div style={{ ...(variables?.theta?.value && { transform: `rotate(${-variables.theta.value}deg)` }) }}>
						<img
							id={`itemImg1_${id}`}
							src={propsIcons[1]}
							alt=""
							draggable={false}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								zIndex: dragging1 ? 1000 : 1,
							}}
							onMouseOver={() => setMeters1Display(true)}
							onMouseOut={() => setMeters1Display(false)}
							onFocus={() => setMeters1Display(true)}
							onBlur={() => setMeters1Display(false)}
						/>
						{meters1Display && !dragging1 && (
							Object.keys(variables).map((variable) => (
								variables[variable].metersDisplay && (
									<div
										key={`meters_${variable}`}
										style={{
											width: `${((2 * variables[variable].value) / resolution)}px`,
											height: `${((2 * variables[variable].value) / resolution)}px`,
											borderRadius: `${((2 * variables[variable].value) / resolution)}px`,
											background: itemCat === "camera"
												? "transparent"
												: (cat === "general"
													? "rgba(19, 78, 218, 0.6)"
													: (cat === "effectors"
														? "rgba(250, 160, 0, 0.6)"
														: "rgba(255, 0, 0, 0.6)"
													)),
											position: "absolute",
											top: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											left: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											filter: "blur(5px)",
											zIndex: -1,
											animation: "areaAlarmAnimation 1.2s linear infinite",
										}}
									/>
								)
							))
						)}
					</div>
				</Tooltip>
				{mode === "edit" && (
					<div
						style={{
							display: (metersTooltip1Open) ? "block" : "none",
							width: "max-content",
							paddingLeft: "5px",
							paddingRight: "5px",
							height: "20px",
							position: "absolute",
							top: `-${(elementDimensions / 2) + 5}px`,
							left: `${(elementDimensions / 2) - (145 / 2)}px`,
							fontSize: "14px",
							textAlign: "center",
							background: "#7a8585",
							borderRadius: "10px",
							color: "white",
							zIndex: 100,
						}}
					>
						{`x: ${metersTooltipPosition.x}m, y: ${metersTooltipPosition.y}m`}
					</div>
				)}
			</div>
		</Draggable>,
		<Draggable
			key={`item2_${id}`}
			allowAnyClick={false}
			{... (mode === "edit" ? { grid: [5, 5] } : {})}
			zIndex={100}
			position={{ x: 0, y: 0 }}
			disabled={mode === "view"}
			onStart={mode === "edit" ? on2Start : () => {}}
			onDrag={mode === "edit" ? on2Drag : () => {}}
			onStop={mode === "edit" ? on2Stop : () => {}}
		>

			<div
				id={`item2_${id}`}
				style={{
					width: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					height: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					borderRadius: `${((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions}px`,
					position: "absolute",
					left: `calc(${position2.x}% - ${(((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions) / 2}px)`,
					bottom: `calc(${position2.y}% - ${(((isHuman || isColor) ? (11 / 15) : (isWall ? (7 / 15) : 1)) * elementDimensions) / 2}px)`,
					border: isColor ? "2px solid #134eda" : "none",
					margin: (isHuman || isColor) ? "6px" : "0px",
					padding: (isHuman || isColor) ? "5px" : "0px",
					boxShadow: (isHuman || isColor) ? "0px 0px 20px 5px rgba(0, 0, 0, 0.5)" : "none",
					background: isHuman
						? "#134eda"
						: "transparent",
					cursor: mode === "view"
						? "help"
						: dragging2 ? "move" : "pointer",
					transform: "translate(-50%, -50%)",
				}}
			>
				<Tooltip
					open={infoTooltip2Open}
					handleOpen={() => {
						if (dragging1 || dragging2 || popupOpen) {
							return;
						}

						if (Object.keys(variables).filter((variable) => variables[variable].tooltipDisplay).length === 0) {
							return;
						}

						setInfoTooltip2Open(true);
					}}
					handleClose={() => setInfoTooltip2Open(false)}
					title={(
						<>
							<div
								key="itemTooltip_name"
								style={{
									display: "flex",
									flexDirection: "column",
									textAlign: "center",
									width: "150px",
									borderRadius: "10px",
									color: "white",
								}}
							>
								<div style={{ fontWeight: "bold", fontSize: "16px" }}>
									{name}
								</div>
							</div>
							{Object.keys(variables)
								.filter((variable) => variables[variable].tooltipDisplay)
								.map((variable) => (
									<div
										key={`itemTooltip_${variable}`}
										style={{
											display: "flex",
											flexDirection: "column",
											textAlign: "center",
											width: "150px",
											padding: "5px",
											borderRadius: "10px",
											color: "white",
										}}
									>
										<div style={{ fontWeight: "bold", fontSize: "16px" }}>
											{toTitleCase(variable)}
										</div>
										<div
											style={{
												height: "fit-content",
												wordBreak: "break-word",
												fontSize: "14px",
											}}
										>
											{
												["switch", "boolean"].includes(variables[variable].type)
													? (variables[variable].value ? "On" : "Off")
													: variables[variable].value || "-"
											}
										</div>
									</div>
								))}
						</>
					)}
				>
					<div style={{ ...(variables?.theta?.value && { transform: `rotate(${-variables.theta.value}deg)` }) }}>
						<img
							id={`itemImg2_${id}`}
							src={propsIcons[2]}
							alt=""
							draggable={false}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								zIndex: dragging2 ? 1000 : 1,
							}}
							onMouseOver={() => setMeters2Display(true)}
							onMouseOut={() => setMeters2Display(false)}
							onFocus={() => setMeters2Display(true)}
							onBlur={() => setMeters2Display(false)}
						/>
						{meters2Display && !dragging2 && (
							Object.keys(variables).map((variable) => (
								variables[variable].metersDisplay && (
									<div
										key={`meters_${variable}`}
										style={{
											width: `${((2 * variables[variable].value) / resolution)}px`,
											height: `${((2 * variables[variable].value) / resolution)}px`,
											borderRadius: `${((2 * variables[variable].value) / resolution)}px`,
											background: itemCat === "camera"
												? "transparent"
												: (cat === "general"
													? "rgba(19, 78, 218, 0.6)"
													: (cat === "effectors"
														? "rgba(250, 160, 0, 0.6)"
														: "rgba(255, 0, 0, 0.6)"
													)),
											position: "absolute",
											top: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											left: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											filter: "blur(5px)",
											zIndex: -1,
											animation: "areaAlarmAnimation 1.2s linear infinite",
										}}
									/>
								)
							))
						)}
					</div>
				</Tooltip>
				{mode === "edit" && (
					<div
						style={{
							display: (metersTooltip2Open) ? "block" : "none",
							width: "max-content",
							paddingLeft: "5px",
							paddingRight: "5px",
							height: "20px",
							position: "absolute",
							top: `-${(elementDimensions / 2) + 5}px`,
							left: `${(elementDimensions / 2) - (145 / 2)}px`,
							fontSize: "14px",
							textAlign: "center",
							background: "#7a8585",
							borderRadius: "10px",
							color: "white",
							zIndex: 100,
						}}
					>
						{`x: ${metersTooltipPosition.x}m, y: ${metersTooltipPosition.y}m`}
					</div>
				)}
			</div>
		</Draggable>,
		<Popup
			key={`popup_${id}`}
			width="500px"
			open={popupOpen}
			title={`${toTitleCase(itemCat)} ${id} Settings`}
			onClose={closePopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Form key={JSON.stringify(formContent)} content={formContent} onSubmit={onSettingsSubmit} />
				<div style={{ width: "100%", height: "10px" }} />
				<ErrorBackgroundButton
					title="Delete"
					width="300px"
					onClick={deleteItem}
				/>
			</Grid>
		</Popup>,
	]);
};

export default DoubleItem;
