import { createSlice } from '@reduxjs/toolkit';

const evaluatePath = (obj, path) => path.split('.').reduce((acc, key) => acc[key], obj);

/**
 * Replaces curly braces in the given data payload variable with corresponding values.
 * @param {Object} data - The data payload containing the variable, nodeId, and data.
 * @returns {Object} - The modified data payload with replaced curly braces.
 */
function replaceCurlyBraces(data) {
	// console.log("Adding variable", data.payload.variable, "from node", data.payload.nodeId);
	// console.log("Data are", data.payload.data);
	if (data.payload.variable === undefined) {
		console.log("Variable is undefined");
	} else {
		const regex = /{([^}]+)}/g;
		let matches = data.payload.variable.match(regex);
		// console.log("Matches", matches);
		// console.log("Matches length", matches?.length);
		while (matches) {
			// console.log("Matches", matches);
			if (matches) {
				for (const match of matches) {
					// eslint-disable-next-line unicorn/prefer-string-slice
					const param = match.substring(1, match.length - 1);
					const value = data.payload.parameters?.find((p) => p.id === param).value;
					data.payload.variable = data.payload.variable.replace(match, value);
				}
			}

			matches = data.payload.variable.match(regex);
		}
	}

	const toAdd = {
		nodeId: data.payload.nodeId,
		variable: data.payload.variable,
		nodeCount: data.payload.count,
		value: data.payload.value,
		literalVariables: data.payload.literalVariables,
	};
	return toAdd;
}

/**
 * @file storageVariablesSlice.js
 * @description Defines a Redux slice for managing storage variables.
 */

/**
 * @typedef {Object} Variable
 * @property {string} variable - The variable name.
 * @property {string} nodeId - The ID of the node associated with the variable.
 */

/**
 * @typedef {Array<Variable>} State - The state of the storage variables.
 */

/**
 * @namespace storageVariablesSlice
 * @description Redux slice for managing storage variables.
 */

/**
 * @function addVariable
 * @memberof storageVariablesSlice
 * @param {State} state - The current state of the storage variables.
 * @param {Variable} data - The variable to add.
 * @returns {void}
 * @description Adds a variable to the state and logs the added variable and its associated node ID.
 */

/**
 * @function removeVariable
 * @memberof storageVariablesSlice
 * @param {State} state - The current state of the storage variables.
 * @param {Variable} variable - The variable to remove.
 * @returns {State} - The updated state after removing the variable.
 * @description Removes variables associated with a specific node ID from the state and logs the node ID being removed.
 */

/**
 * @function clearVariables
 * @memberof storageVariablesSlice
 * @param {State} state - The current state of the storage variables.
 * @returns {State} - An empty state.
 * @description Clears all variables from the state.
 */

/**
 * @function updateVariable
 * @memberof storageVariablesSlice
 * @param {State} state - The current state of the storage variables.
 * @param {Variable} data - The updated variable.
 * @returns {State} - The updated state after updating the variable.
 * @description Updates a variable in the state if it exists and logs the updated variable and its associated node ID.
 */
export const storageVariablesSlice = createSlice({
	name: 'storageVariables',
	initialState: { total: [], used: [] },
	reducers: {
		addVariable: (state, data) => {
			/*
				nodeId: id,
				variable: node.action.storage,
				data: node,
				count: counter + 1,
				value,
			*/

			// Check if variable exists and add
			if (state.total.some((v) => v.variable === data.payload.variable)) {
				// console.log("Variable already exists");
				return;
			}

			const toAdd = replaceCurlyBraces(data);
			state.total.push(toAdd);
			// console.log("Added variable", toAdd);
		},
		setUsedVariables: (state, data) => {
			// console.log("Setting used variables", data.payload);
			state.used = data.payload;
			console.log(state.used);
			return state;
		},
		removeVariable: (state, variable) => {
			console.log("Removing variables", variable.payload);
			// Check if state has variables of that node and remove them
			state.total = state.total.filter((v) => v.nodeId !== variable.payload.nodeId);
			return state;
		},
		clearVariables: (state) => {
			state = { total: [], used: [] };
			return state;
		},
		updateVariable: (state, data) => {
			// Check if index exists and update
			const index = state.total.findIndex((v) => v.nodeId === data.payload.nodeId);
			if (index === -1) {
				console.log("Update: Variable not found");
				return;
			}

			const toUpdate = replaceCurlyBraces(data);
			state.total[index] = toUpdate;
			// console.log("Updated variable", toUpdate.variable, "from node", toUpdate.nodeId);

			// eslint-disable-next-line consistent-return
			return state;
		},
		updateVariableValue: (state, data) => {
			// Check if index exists and update
			// console.log("Var to update:", data.payload);
			const index = state.total.findIndex((v) => v.variable === data.payload.variable);
			if (index === -1) {
				console.log("Update: Variable not found");
				return;
			}

			state.total[index].value = data.payload.value;

			// check if variable is used
			for (const key of Object.keys(state.used)) {
				// console.log(`Key: ${key}, Value: ${state.used[key]}`);
				if (key.includes(data.payload.variable)) {
					console.log("Updating used variable", key, data.payload.value);
					// Check if nested
					state.used[key] = key.includes(".") ? evaluatePath(data.payload.value, key.replace(`${data.payload.variable}.`, "")) : data.payload.value;
				}
			}

			// eslint-disable-next-line consistent-return
			return state;
		},
		setVariables: (state, data) => {
			// console.log(">>>>>>>>> Setting variables", data.payload);
			state = data.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addVariable,
	removeVariable,
	updateVariable,
	clearVariables,
	setVariables,
	setUsedVariables,
	updateVariableValue,
} = storageVariablesSlice.actions;

export default storageVariablesSlice.reducer;
