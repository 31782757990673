import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Navigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import { jwt } from "../utils/index.js";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		height: "calc(100% - 160px)",
		backgroundColor: theme.palette.primary.main,
		position: "fixed",
	},
	mainBox: {
		padding: "10px 20px",
		overflow: "auto",
		position: "absolute",
		display: "flex",
		height: "100%",
	},
}));

const maybeSetToken = (Component) => (props) => {
	const { search } = useLocation();
	const { token } = queryString.parse(search);
	if (token) jwt.setToken(token);
	return <Component {...props} />;
};

const Protected = ({ c }) => {
	const location = useLocation();
	const classes = useStyles();
	const { pathname } = location;

	return jwt.isAuthenticated()
		? (
			<div className={classes.main} style={{ ...(pathname === "/marketplace" ? { backgroundColor: "#0D192B" } : {}) }}>
				<div className={classes.mainBox} style={{ width: "100%" }}>
					<div className="header-container" style={{ height: "100%" }}>
						{c}
					</div>
				</div>
			</div>
		)
		: <Navigate replace to="/" state={{ from: location }} />;
};

Protected.propTypes = { c: PropTypes.node.isRequired };
Protected.whyDidYouRender = true;

export default maybeSetToken(Protected);

