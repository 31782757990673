import barcodeIcon from "../../../assets/images/missionItems/actors/barcode.png";
import colorIcon from "../../../assets/images/missionItems/actors/color_border.png";
import fireIcon from "../../../assets/images/missionItems/actors/fire.png";
import humanIcon from "../../../assets/images/missionItems/actors/human_icon.png";
import humanMoving from "../../../assets/images/missionItems/actors/human_icon_moving.png";
import humanMovingSpeaking from "../../../assets/images/missionItems/actors/human_icon_moving_speaking.png";
import humanSpeaking from "../../../assets/images/missionItems/actors/human_icon_speaking.png";
import qrIcon from "../../../assets/images/missionItems/actors/qr.png";
import rfidIcon from "../../../assets/images/missionItems/actors/rfid.png";
import robotIcon from "../../../assets/images/missionItems/general/robot.png";
import robotFormulaIcon from "../../../assets/images/missionItems/general/robotFormula.png";
import robotMiniIcon from "../../../assets/images/missionItems/general/robotMini.png";
import robotTractorIcon from "../../../assets/images/missionItems/general/robotTractor.png";
import robotDroneIcon from "../../../assets/images/missionItems/general/robotDrone.png";
import robotRoombaIcon from "../../../assets/images/missionItems/general/robotRoomba.png";
import soundIcon from "../../../assets/images/missionItems/actors/sound.png";
import textIcon from "../../../assets/images/missionItems/actors/text.png";
import wallIcon from "../../../assets/images/missionItems/actors/wall.png";
import wallEdge from "../../../assets/images/missionItems/actors/wall_edge.png";
import waterIcon from "../../../assets/images/missionItems/actors/water.png";

const general = {
	title: "General",
	items: {
		barcode: {
			icon: barcodeIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "Barcode",
				body: "A barcode component with a message",
			},
		},
		color: {
			icon: colorIcon,
			variables: {
				r: {
					type: "number",
					name: "Red",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				g: {
					type: "number",
					name: "Green",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				b: {
					type: "number",
					name: "Blue",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Color",
				body: "A simulated area with a specific dominant color",
			},
		},
		fire: {
			icon: fireIcon,
			variables: {
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				temperature: {
					type: "number",
					name: "Temperature",
					value: 150,
					min: 40,
					max: 1000,
					step: 1,
					precision: 1,
					format: "°C",
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Fire",
				body: "A source of fire, denoting large temperatures",
			},
		},
		human: {
			icon: humanIcon,
			variables: {
				move: {
					type: "boolean",
					name: "Move",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				sound: {
					type: "boolean",
					name: "Sound",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				language: {
					type: "select",
					name: "Language",
					value: "EL",
					options: ["EL", "EN"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				speech: {
					type: "text",
					name: "Speech",
					value: "",
					placeholder: "Speech",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				emotion: {
					type: "select",
					name: "Emotion",
					value: "Neutral",
					options: ["Happy", "Sad", "Crying", "Neutral", "Delighted", "Angry", "Disgusted", "Afraid"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				gender: {
					type: "select",
					name: "Gender",
					value: "None",
					options: ["None", "Male", "Female"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				age: {
					type: "number",
					name: "Age",
					value: 20,
					min: 0,
					max: 100,
					step: 1,
					precision: 0,
					format: "years",
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Human",
				body: "A human that can also speak and walk",
			},
			icons: {
				"0_0": humanIcon,
				"0_1": humanSpeaking,
				"1_0": humanMoving,
				"1_1": humanMovingSpeaking,
			},
		},
		qr: {
			icon: qrIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "QR",
				body: "A QR component with a message",
			},
		},
		rfid: {
			icon: rfidIcon,
			variables: {
				message: {
					type: "text",
					name: "Message",
					value: "",
					placeholder: "Message",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "RFID Tag",
				body: "An RFID tag with a message",
			},
		},
		robot: {
			icon: robotIcon,
			variables: {
				theta: {
					type: "number",
					name: "Theta",
					value: 0,
					min: -180,
					max: 180,
					step: 10,
					precision: 0,
					format: "°",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				skin: {
					type: "select",
					name: "Skin",
					value: "Normal",
					options: ["Normal", "Formula", "Mini", "Tractor", "Drone", "Roomba"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				microphone: {
					type: "boolean",
					name: "Microphone",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["microphone_front_"],
					parameters: [["duration"]],
				},
				camera: {
					type: "boolean",
					name: "Camera",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["tektrain_camera_", "pan_tilt_"],
					parameters: [["-"], ["pan", "tilt"]],
				},
				imu: {
					type: "boolean",
					name: "IMU",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["imu_"],
					parameters: [["acceleration.x", "acceleration.y", "acceleration.z", "gyroscope.yaw", "gyroscope.pitch", "gyroscope.roll", "magnetometer.yaw", "magnetometer.pitch", "magnetometer.roll"]],
				},
				env: {
					type: "boolean",
					name: "Environmental",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["env_"],
					parameters: [["data.temperature", "data.pressure", "data.humidity", "data.gas"]],
				},
				speaker: {
					type: "boolean",
					name: "Speaker",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["speaker_front_"],
					parameters: [["text", "volume", "language"]],
				},
				leds: {
					type: "boolean",
					name: "LEDs",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["leds_front_"],
					parameters: [["r", "g", "b", "intensity"]],
				},
				rfid_reader: {
					type: "boolean",
					name: "RFID Reader",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					ids: ["rfid_reader_"],
					parameters: [["data.tags.<tag_name>"]],
				},
				sonar: {
					type: "extra",
					name: "Sonar",
					tooltipDisplay: false,
					metersDisplay: false,
					extra: {
						sonar_bl: {
							type: "boolean",
							name: "Back Left",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_bl_",
							parameters: ["distance"],
						},
						sonar_br: {
							type: "boolean",
							name: "Back Right",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_br_",
							parameters: ["distance"],
						},
						sonar_fl: {
							type: "boolean",
							name: "Front Left",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_fl_",
							parameters: ["distance"],
						},
						sonar_f: {
							type: "boolean",
							name: "Front",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_f_",
							parameters: ["distance"],
						},
						sonar_fr: {
							type: "boolean",
							name: "Front Right",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_fr_",
							parameters: ["distance"],
						},
						sonar_l: {
							type: "boolean",
							name: "Left",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_l_",
							parameters: ["distance"],
						},
						sonar_r: {
							type: "boolean",
							name: "Right",
							value: false,
							tooltipDisplay: false,
							metersDisplay: false,
							id: "sonar_r_",
							parameters: ["distance"],
						},
					},
				},
			},
			tooltip: {
				header: "Robot",
				body: "A robot is a moving agent that is able to interact with the environment via actuators and measure properties of the environment via sensors",
			},
			icons: {
				Normal: {
					image: robotIcon,
					aspectRatio: 1.158,
				},
				Formula: {
					image: robotFormulaIcon,
					aspectRatio: 1.983,
				},
				Mini: {
					image: robotMiniIcon,
					aspectRatio: 1.746,
				},
				Tractor: {
					image: robotTractorIcon,
					aspectRatio: 1.65,
				},
				Drone: {
					image: robotDroneIcon,
					aspectRatio: 1,
				},
				Roomba: {
					image: robotRoombaIcon,
					aspectRatio: 1.004,
				},
			},
		},
		sound: {
			icon: soundIcon,
			variables: {
				language: {
					type: "select",
					name: "Language",
					value: "EL",
					options: ["EL", "EN"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				speech: {
					type: "text",
					name: "Speech",
					value: "",
					placeholder: "Speech",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				emotion: {
					type: "select",
					name: "Emotion",
					value: "Neutral",
					options: ["Happy", "Sad", "Crying", "Neutral", "Delighted", "Angry", "Disgusted", "Afraid"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Sound",
				body: "A sound source",
			},
		},
		text: {
			icon: textIcon,
			variables: {
				text: {
					type: "text",
					name: "Text",
					value: "",
					placeholder: "Text",
					tooltipDisplay: true,
				},
			},
			tooltip: {
				header: "Text",
				body: "A text that denotes a message and can be detected via OCR algorithms",
			},
		},
		wall: {
			icon: wallIcon,
			itemType: "double",
			variables: {},
			icons: {
				1: wallEdge,
				2: wallEdge,
			},
			tooltip: {
				header: "Wall",
				body: "A wall is an obstacle you can place anywhere in the map in order to not allow the robot to pass through. Walls are detectable from the robot’s distance sensors",
			},
		},
		water: {
			icon: waterIcon,
			variables: {
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
			},
			tooltip: {
				header: "Water",
				body: "A source of water, denoting high humidity",
			},
		},
	},
};

export default general;
