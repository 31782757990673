import OpenapiIcon from "../assets/dsls-logos/openapi_L.png";

/**
 * Retrieves the constants for the OpenAPI domain specific language.
 * @returns {Promise<Object>} The constants object.
 */
export const getOpenAPIConstants = async () => {
	const constants = {
		enabled: true,
		_id: "openapi",
		name: "OpenAPI",
		short: "openapi",
		description: "The OpenAPI Specification, previously known as the Swagger Specification, is a specification for a machine-readable interface definition language for describing, producing, consuming and visualizing web services.",
		logoUrl: OpenapiIcon,
		logoUrlDark: OpenapiIcon,
		insertableEntities: {},
		shortDescription: "",
		documentation: "",
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: ["dflow"],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		supportsDeployment: false,
		generationLanguage: "",
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["Network", "Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};
