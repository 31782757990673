// import { shortDescription } from "./envmaker/short-description.js";
// import { getDocumentation } from "./envmaker/documentation.js";

// eslint-disable-next-line camelcase
import AppcreatorLight from "../assets/dsls-logos/appcreator_L.png";
// eslint-disable-next-line camelcase
import AppcreatorDark from "../assets/dsls-logos/appcreator_D.png";

/**
 * Retrieves the constants for the AppCreator DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getAppCreatorConstants = async () => {
	const constants = {
		enabled: true,
		_id: "appcreator",
		name: "AppCreator",
		short: "appcreator",
		description: "Tool for visually creating system applications.",
		// eslint-disable-next-line camelcase
		logoUrl: AppcreatorLight,
		// eslint-disable-next-line camelcase
		logoUrlDark: AppcreatorDark,
		insertableEntities: {},
		shortDescription: "Tool for visually creating system applications.",
		documentation: "Tool for visually creating system applications.",
		subtypes: [],
		hasValidation: true,
		type: "Graphical",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		generationLanguage: "",
		supportsDeployment: true,
		generations: ["Default"],
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Graphical"],
		// autosave: false,
		initiallyCollapsed: true,
	};
	return constants;
};
