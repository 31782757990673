const getMapDimensions = (mainmapWidth, mainmapHeight) => {
	const availWidth = mainmapWidth;
	const availHeight = mainmapHeight;
	const initRatio = mainmapWidth / mainmapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const getElementDimension = (mapWidth, mapHeight, nRows, nCols) => {
	const width = mapWidth / nCols;
	const height = mapHeight / nRows;
	return Math.min(width, height);
};

export { getMapDimensions, getElementDimension };
