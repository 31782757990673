
import { useEffect, useState } from "react";

import {
	Grid,
	Typography,
	Select,
	MenuItem,
} from "@mui/material";

import {
	getUsersModels,
} from '../../api/index.js';

import { useSnackbar, jwt } from "../../utils/index.js";

const ParametersEnvpop = ({ param, parameters, setParameters, nodeData }) => {
	const { error, success } = useSnackbar();
	const [user, setUser] = useState(jwt.decode());
	const [model, setModel] = useState(parameters.find((p) => p.id === param.id).value);
	console.log("In envpop models dropdown");
	const [models, setModels] = useState([]);

	useEffect(() => {
		console.log("new parameters", parameters);
		async function fetchModels() {
			// Fetch the user's models
			const { success: scs, models: mds } = await getUsersModels(user.id, "envpop", true);
			console.log("Models", mds);
			if (scs) {
				setModels(mds);
			} else {
				error();
			}
		}

		setModel(parameters.find((p) => p.id === param.id).value);
		fetchModels();
	}, [parameters]);

	return (
		<Grid
			item
			container
			display="flex"
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			sx={{
				marginBottom: "5px",
				width: "100%",
			}}
		>
			<Typography sx={{ marginRight: "10px", width: "40%" }}>
				{param.label}
			</Typography>
			<Select
				variant="filled"
				sx={{
					width: "55%",
				}}
				value={model ?? ""}
				onChange={(e) => {
					console.log("Target", e.target);
					const newParams = parameters.map((p) => {
						console.log("In map", p);
						if (p.id === param.id) {
							console.log("In if", p);
							return {
								...p,
								value: e.target.value,
							};
						}

						return p;
					});
					console.log(newParams);
					setParameters(newParams);
				}}
			>
				<MenuItem
					key="none"
					value=""
				>
					{""}
				</MenuItem>
				{models.map((option) => (
					<MenuItem
						key={option.id}
						value={option.title}
					>
						{option.title}
					</MenuItem>
				))}
			</Select>
		</Grid>
	);
};

export default ParametersEnvpop;
