import { getDocumentation } from "./xmasdsl/documentation.js";
import { DelayExample, DimExample, Example, GroupExample, LinearExample, ParallelExample, ProgramExample, RainbowExample, RepeatExample, SerialExample, SetPixelColorExample } from "./xmasdsl/insertables.js";

import XmasdslLight from "../assets/dsls-logos/xmasdsl_L.png";
import XmasdslDark from "../assets/dsls-logos/xmasdsl_D.png";

/**
 * Retrieves the constants for the XmasDSL.
 * @returns {Promise<Object>} The constants for the XmasDSL.
 */
export const getXmasdslConstants = async () => {
	const constants = {
		enabled: false,
		_id: "xmasdsl",
		name: "XmasDSL",
		short: "xmasdsl",
		description: "A textual DSL that allows manipulation of a led strip, placed on a Christmass tree! #Hohoho.",
		logoUrl: XmasdslLight,
		logoUrlDark: XmasdslDark,
		insertableEntities: {
			SimpleExample: Example,
			Program: ProgramExample,
			Serial: SerialExample,
			Parallel: ParallelExample,
			Group: GroupExample,
			Repeat: RepeatExample,
			SetPixelColor: SetPixelColorExample,
			Dim: DimExample,
			Rainbow: RainbowExample,
			Linear: LinearExample,
			Delay: DelayExample,
		},
		shortDescription: await getDocumentation(),
		documentation: await getDocumentation(),
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		supportsDeployment: true,
		generationLanguage: "javascript",
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [
			"Range",
			"RandomRange",
			"Program",
			"Serial",
			"Parallel",
			"Repeat",
			"Group",
			"SetPixelColor",
			"Dim",
			"Color",
			"RandomColor",
			"Linear",
			"Rainbow",
			"Delay",
			"SetBrightness",
		],
		llmSupport: false,
		tags: ["Fun", "Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};
