import CommidlLight from "../assets/dsls-logos/commidl_L.png";
import CommidlDark from "../assets/dsls-logos/commidl_D.png";

/**
 * Retrieves the Comm-IDL constants.
 * @returns {Promise<Object>} The Comm-IDL constants.
 */
export const getCommidlConstants = async () => {
	const constants = {
		enabled: false,
		_id: "commidl",
		name: "Comm-IDL",
		short: "commidl",
		description: "Comm-IDL is an Interface-Definition Language built upon MDE methodologies, for message-driven async communication systems. Such systems include message brokers, such as RabbitMQ, Redis, NATS etc.",
		logoUrl: CommidlLight,
		logoUrlDark: CommidlDark,
		insertableEntities: {},
		shortDescription: "Comm-IDL is an Interface-Definition Language built upon MDE methodologies, for message-driven async communication systems. Such systems include message brokers, such as RabbitMQ, Redis, NATS etc.",
		documentation: "Comm-IDL is an Interface-Definition Language built upon MDE methodologies, for message-driven async communication systems. Such systems include message brokers, such as RabbitMQ, Redis, NATS etc.",
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		supportsDeployment: false,
		generationLanguage: "javascript",
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};
