import { useState } from "react";
import { Card, CardContent, Typography, IconButton, Grid, Box, Chip, Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import { ContentCopy, DeleteOutline, Visibility, Close } from "@mui/icons-material";
import AreYouSurePopup from "./Popups/AreYouSurePopup";
import { removeModelFromMarketplace, cloneModelFromStore, newProject, addModelToProject } from "../api/index.js";
import Form from "./Form.js";
import Popup from "./Popup.js";

const ModelCard = ({
    model,
    setIsLoading,
    success,
    error,
    fetchData,
    textualDsls,
    graphicalDsls,
    projects,
    fromProject = false,
    publicProjects,
    dslImage,
}) => {
    const [removePopupOpen, setRemovePopupOpen] = useState(false);
    const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
    const [viewPopupOpen, setViewPopupOpen] = useState(false);

    const { title, model_type, description, createdAt, isUser, creator, shopEditable, model_text, tags } = model;

    const newModelFormContent = [
        {
            customType: "wideInput",
            id: "title",
            type: "input",
            multiline: false,
            width: 400,
            placeholder: "Cloned model title",
            required: true,
        },
        {
            customType: "wideInput",
            id: "description",
            type: "input",
            multiline: true,
            minRows: 3,
            width: 400,
            placeholder: "Cloned model description",
        },
        {
            customType: "autocomplete",
            id: "project",
            options: projects ? projects.map((project) => project?.title) : [],
            multiple: true,
            label: "Select project",
            width: 400,
            filled: false,
        },
        {
            customType: "button",
            id: "submit",
            type: "submit",
            text: "Clone model",
        },
    ];

    const handleCloneModel = async (values) => {
        setIsLoading(true);
        console.log("Values", values)
        // Check that the project has value
        if (values?.project !== "" || !values?.project) {
            // Checks if the given project is an already existing project
            const projectExistance = projects.find((project) => project?.title === values?.project);
            console.log("PROJECT EXISTENCE", projectExistance)
            // Creates the model
            const { success: scs, message, newModel } = await cloneModelFromStore(model._id, values.title, values.description);
            if (scs) {
                if (projectExistance) {
                    // If the given project exists it adds the created project in the project
                    const { success: scs, message } = await addModelToProject(projectExistance._id, newModel._id);
                    if (scs) {
                        success("Model cloned successfully");
                        const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
                        window.open(newTabUrl, '_blank')
                    } else {
                        error(message);
                    }
                } else {
                    // If the given project does not exist it creates the project and then adds the cloned model inside the project
                    const { success: scs, message, result } = await newProject(values?.project);
                    if (scs) {
                        const { success: scs, message } = await addModelToProject(result._id, newModel._id);
                        if (scs) {
                            success("Model cloned successfully");
                            const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
                            window.open(newTabUrl, '_blank')
                        } else {
                            error(message);
                        }
                    } else {
                        error(message);
                    }
                }
            } else {
                error(message);
            }
        } else {
            // If the project does not have value just clone the model
            const { success: scs, message } = await cloneModelFromStore(model._id, values.title, values.description);
            if (scs) {
                success(message);
                const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
                window.open(newTabUrl, '_blank');
            } else {
                error(message);
            }
        }

        setCloneModelPopupOpen(false);
        setIsLoading(false);
    };

    const onRemoveClicked = () => {
        setRemovePopupOpen(true);
    };

    const declineRemoveModelFromMarketplace = () => {
        setRemovePopupOpen(false);
    };

    const handleRemoveModelFromMarketplace = async () => {
        setIsLoading(true);
        const { success: scs, message } = await removeModelFromMarketplace(model._id);
        if (scs) {
            success(message);
        } else {
            error(message);
        }

        await fetchData();
        setRemovePopupOpen(false);
        setIsLoading(false);
    };

    return (
        <>
            <AreYouSurePopup
                open={removePopupOpen}
                title="Remove model from Marketplace?"
                content="Are you sure you want to remove this model from Marketplace?"
                onDecline={declineRemoveModelFromMarketplace}
                onAccept={handleRemoveModelFromMarketplace}
            />
            <Popup
                width="800px"
                open={cloneModelPopupOpen}
                title="Clone Model"
                onClose={() => {
                    setCloneModelPopupOpen(false);
                }}
                titleColor="#0D192B"
                titleBackgroundColor="#ABC7D9"
                backgroundColor="#DDE8EF"
                closeIconColor="#0D192B"
            >
                <Form
                    content={newModelFormContent}
                    onSubmit={handleCloneModel}
                />
            </Popup>
            <Dialog
                open={viewPopupOpen}
                onClose={() => setViewPopupOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle sx={{
                    backgroundColor: "#ABC7D9",
                    variant: "h7",
                    fontWeight: "bold"
                }}>
                    {model?.title}
                    <IconButton
                        onClick={() => setViewPopupOpen(false)}
                        sx={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        overflowY: "auto",
                        whiteSpace: "pre-wrap",
                        fontFamily: model_type === "textualDsl" ? "monospace" : "inherit",
                        maxHeight: "80vh",
                        padding: 2,
                        backgroundColor: "#DDE8EF"
                    }}
                >
                    {textualDsls.map(item => item.replace(/-/g, "")).includes(model?.model_type) ? (
                        <Typography
                            sx={{
                                fontFamily: "monospace",
                                color: "text.primary",
                            }}
                        >
                            {model_text || "No model text available."}
                        </Typography>
                    ) : (
                        graphicalDsls.includes(model?.model_type) && (
                            <img
                                src={""}
                                alt={model?.title || "Model Preview"}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    borderRadius: "8px",
                                }}
                            />
                        )
                    )}
                </DialogContent>
            </Dialog>
            <Card
                sx={{
                    width: "100%",
                    height: 300,
                    maxWidth: 400,
                    margin: 2,
                    boxShadow: 6,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "#323D4D",
                    "&:hover": {
                        boxShadow: 12,
                        transform: "scale(1.02)",
                        transition: "all 0.3s ease",
                        "& .custom-scrollbar::-webkit-scrollbar": {
                            visibility: "visible",
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#ABC7D9",
                        padding: 1,
                        color: "white.main",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                    }}
                >
                    <Typography
                        variant="h7"
                        fontWeight="bold"
                        sx={{
                            wordWrap: "break-word",
                            textAlign: "start",
                            color: "black",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {title || "Untitled"}
                    </Typography>

                </Box>

                <CardContent
                    className="custom-scrollbar"
                    sx={{
                        flex: 1,
                        overflowY: "auto",
                        maxHeight: "150px",
                        padding: 2,
                        scrollbarWidth: "none",
                    }}
                >
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                    wordWrap: "break-word",
                                    fontStyle: "italic",
                                    fontWeight: "bold",
                                    color: "white !important",
                                    mb: "1rem",
                                }}
                            >
                                {description || "No description available."}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                    color: "secondary.main",
                                }}
                            >
                                Created At: {new Date(createdAt).toLocaleDateString()}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            {tags && tags.length > 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 1,
                                        marginTop: 1,
                                    }}
                                >
                                    {tags.map((tag, index) => (
                                        tag !== "" && (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                size="small"
                                                sx={{
                                                    backgroundColor: "secondary.dark",
                                                    color: "white.main",
                                                    fontSize: "0.80rem",
                                                    fontWeight: 500,
                                                }}
                                            />
                                        )
                                    ))}
                                </Box>
                            )}
                        </Grid> */}
                        {!fromProject && model?.publishedInProject && (
                            <Grid item>
                                <Typography
                                    variant="caption"
                                    color="#ABC7D9 !important"
                                    sx={{
                                        fontSize: "14px",
                                    }}
                                >
                                    Published in Project: {
                                        publicProjects.find((prj) => prj?.models.includes(model._id))?.title || "Unknown"}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                    color: "white.main",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                Created By: {creator?.fullname || "Unknown"}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <Box sx={{ padding: 2, position: "relative" }}>
                    <Grid item>
                        <img
                            src={dslImage}
                            alt={dslImage}
                            style={{
                                height: 30,
                            }}
                        />
                    </Grid>
                    <Divider sx={{ backgroundColor: "white.main", marginBottom: "1rem" }} />
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Chip
                                label={shopEditable ? "Editable" : "Non-Editable"}
                                color={shopEditable ? "success" : "error"}
                                size="small"
                                sx={{
                                    fontWeight: 500,
                                    color: "black",
                                    fontSize: "0.875rem",
                                    background: shopEditable ? "#03DAC5 !important" : "#CF6679 !important",
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                {/* View Model Button */}
                                <IconButton
                                    aria-label="view model"
                                    onClick={() => setViewPopupOpen(true)}
                                    sx={{
                                        backgroundColor: "#ABC7D9 !important",
                                        borderRadius: "50%",
                                        boxShadow: 3,
                                        padding: 1,
                                        "&:hover": {
                                            backgroundColor: "#0D192B !important",
                                            "& .MuiSvgIcon-root": {
                                                color: "#ABC7D9 !important",
                                            },
                                        },
                                    }}
                                >
                                    <Visibility sx={{
                                        color: "#0D192B !important",
                                    }} />
                                </IconButton>

                                {/* Clone Model Button */}
                                {!fromProject && (
                                    <IconButton
                                        aria-label="clone model"
                                        onClick={() => setCloneModelPopupOpen(true)}
                                        sx={{
                                            backgroundColor: "#ABC7D9 !important",
                                            borderRadius: "50%",
                                            boxShadow: 3,
                                            padding: 1,
                                            "&:hover": {
                                                backgroundColor: "#0D192B !important",
                                                "& .MuiSvgIcon-root": {
                                                    color: "#ABC7D9 !important",
                                                },
                                            },
                                        }}
                                    >
                                        <ContentCopy sx={{
                                            color: "#0D192B !important",
                                        }} />
                                    </IconButton>
                                )}

                                {/* Remove Model Button */}
                                {isUser && !fromProject && !model?.publishedInProject && (
                                    <IconButton
                                        aria-label="remove model"
                                        onClick={() => onRemoveClicked(model)}
                                        sx={{
                                            backgroundColor: "transparent",
                                            border: `2px solid #CF6679`,
                                            borderRadius: "50%",
                                            boxShadow: 3,
                                            padding: 1,
                                            "&:hover": {
                                                backgroundColor: "#CF6679",
                                                borderColor: "#CF6679",
                                                "& .MuiSvgIcon-root": {
                                                    color: "#0D192B",
                                                },
                                            },
                                        }}
                                    >
                                        <DeleteOutline sx={{
                                            color: "#CF6679",
                                        }} />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </>
    );
};

export default ModelCard;
