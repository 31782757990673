import { Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Draggable from "react-draggable";

import Accordion from "../Accordion.js";
import { getElementDimension, getMapDimensions } from "../create-map-2/utilities.js";
import Slider from "../Slider.js";
import Switch from "../Switch.js";
import availableItems from "./items/index.js";
import Item from "./items/item.js";
import DoubleItem from "./items/double-item.js";
import CustomNumericInput from "../NumericInput.js";
import { connect, connectToGeneralBroker, disconnect, disconnectFromGeneralBroker, subscribeToQueue } from "../../utils/websocket.js";
import useSnackbar from "../../utils/use-snackbar.js";
import jwt from "../../utils/jwt.js";
import Spinner from "../Spinner.js";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		position: "relative",
	},
	mapRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.1)",
		position: "relative",
		zIndex: 0,
	},
	controlsRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		color: "white",
		overflowY: "auto",
		overflowX: "hidden",
	},
	map: {
		padding: "0px",
		margin: "0px",
		backgroundColor: "white",
		position: "relative",
	},
	sliderBox: {
		width: "100%",
		padding: "0px 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	optionsRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	divider: {
		width: "90%",
		margin: "10px 0px",
		backgroundColor: theme.palette.greyDark.main,
	},
	tilesBox: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	menuTile: {
		width: "70px",
		margin: "5px",
		cursor: "move",
	},
	draggingTile: {
		width: "30px",
		height: "30px",
		borderRadius: "30px",
		backgroundColor: theme.palette.secondary.main,
		opacity: 0.6,
		position: "fixed",
		cursor: "move",
		zIndex: 1,
		transform: "translate(-50%, -50%)",
	},
}));

const INITIAL_GRID_METERS = 20;
const INITIAL_GRID_DIMENSION = 120;

const categoriesPrefix = {
	general: "gn",
	sensors: "sn",
	effectors: "ef",
};

const CreateMission = forwardRef(({
	model: propsModel = null,
	dbitem: propsDbItem = null,
	inSimulation: propsInSimulation = false,
	modelUpdate: propsModelUpdate = () => {},
}, ref) => {
	const classes = useStyles();
	const { success, error, info, warning } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);

	const [nRows, setNRows] = useState(6);
	const [nCols, setNCols] = useState(9);
	const [boxes, setBoxes] = useState({});
	const [grid, setGrid] = useState([]);
	const [autoWalls, setAutoWalls] = useState([]);

	const [resolution, setResolution] = useState(INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION);
	const [gridMeters, setGridMeters] = useState(INITIAL_GRID_METERS);
	const [gridDimension, setGridDimension] = useState(INITIAL_GRID_DIMENSION);
	const [mapWidth, setMapWidth] = useState(100);
	const [mapHeight, setMapHeight] = useState(80);
	const [mapWidthMeters, setMapWidthMeters] = useState(100 * (INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION));
	const [temperature, setTemperature] = useState(22);
	const [humidity, setHumidity] = useState(60);
	const [luminosity, setLuminosity] = useState(80);
	const [ph, setPh] = useState(7);

	const [useAutoItems, setUseAutoItems] = useState(true);
	const [showAutoItems, setShowAutoItems] = useState(false);
	const [showMapGuides, setShowMapGuides] = useState(true);
	const [nextId, setNextId] = useState(1);
	const [items, setItems] = useState({});
	const [elementDimension, setElementDimension] = useState(30);
	const [objects, setObjects] = useState([]);

	const [dragging, setDragging] = useState(false);
	const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });
	const [descriptionOpen, setDescriptionOpen] = useState(false);
	const [descriptionTitle, setDescriptionTitle] = useState("");
	const [descriptionBody, setDescriptionBody] = useState("");

	const [inSimulation, setInSimulation] = useState(false);
	const [connection, setConnection] = useState(null);
	const [connected, setConnected] = useState(false);

	const connectionRef = useRef();
	connectionRef.current = connection;
	const itemsRef = useRef();
	itemsRef.current = items;
	const mapWidthRef = useRef();
	mapWidthRef.current = mapWidth;
	const mapHeightRef = useRef();
	mapHeightRef.current = mapHeight;

	const updateModel = (values) => {
		const tmpModel = {
			resolution,
			gridMeters,
			gridDimension,
			mapWidth,
			mapHeight,
			mapWidthMeters,
			temperature,
			humidity,
			luminosity,
			ph,
			useAutoItems,
			showAutoItems,
			nextId,
			items,
			autoWalls,
		};

		for (const [key, value] of Object.entries(values)) {
			tmpModel[key] = value;
		}

		propsModelUpdate(JSON.stringify({ ...tmpModel }));
	};

	const firstChangeMapDimensions = () => {
		const mainmap = document.querySelector("#mainmap");
		const mainmapWidth = mainmap.offsetWidth;
		const mainmapHeight = mainmap.offsetHeight;
		const { width, height } = getMapDimensions(mainmapWidth, mainmapHeight);
		setMapWidth(width);
		setMapHeight(height);
		setMapWidthMeters(width * resolution);
		setElementDimension((35 / 750) * width);
	};

	const changeMapDimensions = () => {
		const mainmap = document.querySelector("#mainmap");
		const mainmapWidth = mainmap.offsetWidth;
		const mainmapHeight = mainmap.offsetHeight;
		const { width, height } = getMapDimensions(mainmapWidth, mainmapHeight);
		const gridD = gridMeters * (width / mapWidthMeters);
		setMapWidth(width);
		setMapHeight(height);
		setElementDimension((35 / 750) * width);
		setGridDimension(gridD);
		setResolution(gridMeters / gridD);
	};

	const changeMapWidthMeters = (value) => {
		const newResolution = value / mapWidth;
		const newGridMeters = gridDimension * newResolution;
		setGridMeters(newGridMeters);
		setResolution(newResolution);
		setMapWidthMeters(value);
		updateModel({ gridMeters: newGridMeters, resolution: newResolution, mapWidthMeters: value });
	};

	const changeUseAutoItems = (event) => {
		setUseAutoItems(!useAutoItems);
		if (!event.target.checked) {
			setShowAutoItems(false);
			updateModel({ useAutoItems: !useAutoItems, showAutoItems: false });
			return;
		}

		updateModel({ useAutoItems: !useAutoItems });
	};

	const changeShowAutoItems = () => {
		setShowAutoItems(!showAutoItems);
		updateModel({ showAutoItems: !showAutoItems });

		if (!showAutoItems) {
			warning("Displaying the auto-walls may cause the whole application to speed down.");
		}
	};

	const changeShowMapGuides = () => {
		setShowMapGuides(!showMapGuides);
	};

	const createNewItem = (cat, itemCat, x, y) => {
		const newItem = {
			id: nextId,
			itemName: `${categoriesPrefix[cat]}_${itemCat}_${nextId}`,
			itemCat,
			cat,
			x,
			y,
			x1: x - (elementDimension / mapWidth) * 100,
			y1: y,
			x2: x + (elementDimension / mapWidth) * 100,
			y2: y,
			variables: availableItems[cat].items[itemCat].variables,
			itemType: availableItems[cat].items[itemCat].itemType,
		};

		const tmpItems = { ...items };
		tmpItems[cat][itemCat][nextId] = newItem;
		setItems(tmpItems);
		setNextId(nextId + 1);
		updateModel({ items: tmpItems, nextId: nextId + 1 });
	};

	const updateItem = (cat, itemCat, id, newState) => {
		const tmpItems = { ...items };
		tmpItems[cat][itemCat][id] = newState;
		setItems(tmpItems);
		updateModel({ items: tmpItems });
	};

	const deleteItem = (cat, itemCat, id) => {
		const tmpItems = { ...items };
		delete tmpItems[cat][itemCat][id];
		setItems(tmpItems);
		updateModel({ items: tmpItems });
	};

	const checkNameExists = (nameToCheck, initialName, cat, itemCat, id) => {
		if (nameToCheck === initialName) {
			return { matchesPattern: false, found: false };
		}

		const tmpItems = { ...items };
		let found = false;
		let matchesPattern = false;
		for (const category of Object.keys(tmpItems)) {
			for (const itemCategory of Object.keys(tmpItems[category])) {
				const checkResult = nameToCheck.match(new RegExp(`${categoriesPrefix[cat]}_${itemCategory}_[1-9]{1,3}`, 'g'));
				if (checkResult && checkResult[0] === nameToCheck) {
					matchesPattern = true;
				}

				for (const item of Object.keys(tmpItems[category][itemCategory])) {
					if (tmpItems[category][itemCategory][item].itemName === nameToCheck
						&& !(category === cat && itemCategory === itemCat && Number.parseInt(item, 10) === id)) {
						found = true;
						break;
					}
				}
			}
		}

		return { matchesPattern, found };
	};

	const changeTemperature = (value) => {
		setTemperature(value);
		updateModel({ temperature: value });
	};

	const changeHumidity = (value) => {
		setHumidity(value);
		updateModel({ humidity: value });
	};

	const changeLuminosity = (value) => {
		setLuminosity(value);
		updateModel({ luminosity: value });
	};

	const changePh = (value) => {
		setPh(value);
		updateModel({ ph: value });
	};

	const onDrag = (x, y) => {
		setDraggingPosition({ x, y });
		setDragging(true);
	};

	const onDragStop = () => {
		setDraggingPosition({ x: 0, y: 0 });
		setDragging(false);
	};

	const updateRobotPose = (x, y, theta, name) => {
		const tmpItems = { ...itemsRef.current };
		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (tmpItems[cat][itemCat][item].itemName === name) {
						tmpItems[cat][itemCat][item].x = (x / mapWidthRef.current) * 100;
						tmpItems[cat][itemCat][item].y = (y / mapHeightRef.current) * 100;
						tmpItems[cat][itemCat][item].variables.theta.value = theta;
					}
				}
			}
		}

		setItems(tmpItems);
	};

	const onMessage = (msg) => {
		switch (msg.type) {
			case "connectedToBroker": {
				setIsLoading(false);
				setConnected(true);
				success("Connected to broker");
				break;
			}

			case "errorConnectingToBroker": {
				setIsLoading(false);
				error("There was an error trying to connect to broker. Please check the credentials.");
				break;
			}

			case "disconnectedFromBroker": {
				setIsLoading(false);
				setConnected(false);
				info("Disconnected from broker");
				disconnect({
					connection: connectionRef.current,
					onDisconnect: () => info("Disconnected from websocket"),
				});
				setConnected(false);
				break;
			}

			case "errorDisconnectingFromBroker": {
				setIsLoading(false);
				error("There was an error trying to disconnect from broker");
				break;
			}

			case "disconnectedFromQueue": {
				setIsLoading(false);
				disconnectFromGeneralBroker({ connection: connectionRef.current });
				break;
			}

			case "errorDisconnectingFromQueue": {
				setIsLoading(false);
				error("There was an error trying to disconnect from queue");
				break;
			}

			case "brokerMessage": {
				const { type, data } = JSON.parse(msg.message);
				console.log(type);
				console.log(data);

				switch (type) {
					case "robot_pose": {
						const { x, y, theta, name } = data;
						updateRobotPose(x, y, theta, name);
						break;
					}

					default: {
						break;
					}
				}

				break;
			}

			default: {
				setIsLoading(false);
				info("Unknown message type");
			}
		}
	};

	useEffect(() => {
		if (propsDbItem) {
			for (const imp of propsDbItem?.finalImports ?? []) {
				if (imp.model_type === "envmaker") {
					const modelJSON = JSON.parse(imp.model_text);
					setNRows(modelJSON?.nRows ?? 6);
					setNCols(modelJSON?.nCols ?? 9);
					setBoxes(modelJSON?.boxes ?? {});
					setAutoWalls(modelJSON?.autoWalls ?? []);
					setMapWidth(modelJSON?.mapWidth ?? 100);
					setMapHeight(modelJSON?.mapHeight ?? 80);
					break;
				}
			}
		}

		if (propsModel) {
			const modelJSON = JSON.parse(propsModel);
			setResolution(modelJSON?.resolution ?? INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION);
			setGridMeters(modelJSON?.gridMeters ?? INITIAL_GRID_METERS);
			setGridDimension(modelJSON?.gridDimension ?? INITIAL_GRID_DIMENSION);
			setMapWidth(modelJSON?.mapWidth ?? 100);
			setMapHeight(modelJSON?.mapHeight ?? 80);
			setMapWidthMeters(modelJSON?.mapWidthMeters ?? 100 * (INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION));
			setTemperature(modelJSON?.temperature ?? 22);
			setHumidity(modelJSON?.humidity ?? 60);
			setLuminosity(modelJSON?.luminosity ?? 80);
			setPh(modelJSON?.ph ?? 7);
			setUseAutoItems(modelJSON?.useAutoItems ?? true);
			setShowAutoItems(modelJSON?.showAutoItems ?? true);
			setNextId(modelJSON?.nextId ?? 1);
			setElementDimension((35 / 750) * (modelJSON?.mapWidth ?? 100));
			const tmpItems = { ...(modelJSON?.items) };
			for (const cat of Object.keys(availableItems)) {
				if (!tmpItems[cat]) {
					tmpItems[cat] = {};
				}

				for (const itemCat of Object.keys(availableItems[cat].items)) {
					if (!tmpItems[cat][itemCat]) {
						tmpItems[cat][itemCat] = {};
					}
				}
			}

			setItems(tmpItems);
		}

		changeMapDimensions();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propsDbItem, propsModel]);

	useEffect(() => {
		const tmpBoxes = { ...boxes };
		for (let i = 0; i < nRows; i++) {
			for (let j = 0; j < nCols; j++) {
				tmpBoxes[`${i + 1}_${j + 1}`] = {
					r: i + 1,
					c: j + 1,
					rotation: 0,
					content: null,
					contentId: null,
					contentCat: null,
				};
			}
		}

		setBoxes(tmpBoxes);

		const tmpItems = { ...items };
		for (const cat of Object.keys(availableItems)) {
			if (!tmpItems[cat]) {
				tmpItems[cat] = {};
			}

			for (const itemCat of Object.keys(availableItems[cat].items)) {
				if (!tmpItems[cat][itemCat]) {
					tmpItems[cat][itemCat] = {};
				}
			}
		}

		setItems(tmpItems);

		firstChangeMapDimensions();
		window.addEventListener("resize", changeMapDimensions);

		return () => {
			window.removeEventListener("resize", changeMapDimensions);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const tileDimension = getElementDimension(mapWidth, mapHeight, nRows, nCols);
		const tmpGrid = [];
		for (const box of Object.keys(boxes)) {
			tmpGrid.push(
				<Draggable
					key={box}
					disabled
					allowAnyClick={false}
					position={{ x: 0, y: 0 }}
				>
					<div
						key={box}
						id={box}
						style={{
							display: "flex",
							width: tileDimension,
							height: tileDimension,
							border: "",
							position: "absolute",
							left: ((mapWidth - nCols * tileDimension) / 2
								+ (boxes[box].c - 1) * tileDimension),
							top: ((mapHeight - nRows * tileDimension) / 2
								+ (boxes[box].r - 1) * tileDimension),
							justifyContent: "center",
							opacity: 0.5,
						}}
					>
						{boxes[box].content
							&& (
								<img src={boxes[box].content} alt="" style={{ maxWidth: "100%", maxHeight: "100%", transform: `rotate(${boxes[box].rotation}deg)` }} />
							)}
					</div>
				</Draggable>,
			);
		}

		setGrid(tmpGrid);
	}, [boxes, mapWidth, mapHeight, nRows, nCols]);

	useEffect(() => {
		const tmpObjects = [];
		for (const cat of Object.keys(items)) {
			for (const itemCat of Object.keys(items[cat])) {
				for (const item of Object.keys(items[cat][itemCat])) {
					const itm = items[cat][itemCat][item];
					const props = {
						key: itm.id,
						id: itm.id,
						mode: inSimulation ? "view" : "edit",
						name: itm.itemName,
						initialName: itm.itemName,
						position: { x: itm.x, y: itm.y },
						position1: { x: itm.x1, y: itm.y1 },
						position2: { x: itm.x2, y: itm.y2 },
						icon: availableItems[cat].items[itemCat].icon,
						variables: itm.variables,
						icons: availableItems[cat].items[itemCat].icons,
						elementDimensions: elementDimension,
						mapWidth,
						mapHeight,
						resolution,
						cat,
						itemCat,
						updateItem,
						deleteItem,
						checkNameExists,
					};

					tmpObjects.push(
						(availableItems[cat].items[itemCat].itemType === "double"
							? <DoubleItem {...props} />
							: <Item {...props} />
						),
					);
				}
			}
		}

		if (useAutoItems && showAutoItems) {
			for (const wall of autoWalls) {
				const props = {
					key: wall.id,
					id: wall.id,
					mode: "view",
					name: `auto_wall_${wall.id}`,
					initialName: `auto_wall_${wall.id}`,
					position1: { x: wall.x1, y: wall.y1 },
					position2: { x: wall.x2, y: wall.y2 },
					icons: availableItems.general.items.wall.icons,
					elementDimensions: elementDimension,
					mapWidth,
					mapHeight,
					resolution,
					cat: "general",
					itemCat: "wall",
				};

				tmpObjects.push(<DoubleItem {...props} />);
			}
		}

		const mapGuides = [];
		if (showMapGuides) {
			for (let i = 1; i < 10; i++) {
				mapGuides.push(
					<div
						key={`line-h-${i}`}
						style={{
							position: "absolute",
							left: 0,
							top: (mapHeight / 10) * i,
							width: mapWidth,
							height: 1,
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						}}
					/>,
					<div
						key={`line-v-${i}`}
						style={{
							position: "absolute",
							left: (mapWidth / 10) * i,
							top: 0,
							width: 1,
							height: mapHeight,
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						}}
					/>,
				);
			}

			for (let i = 1; i < 10; i++) {
				mapGuides.push(
					<div
						key={`text-h-${i}`}
						style={{
							position: "absolute",
							left: 5,
							top: (mapHeight / 10) * (10 - i) - 10,
							color: "black",
							background: "white",
							fontSize: "12px",
						}}
					>
						{`${(i * ((mapHeight * resolution) / 10)).toFixed(1)}m`}
					</div>,
					<div
						key={`text-v-${i}`}
						style={{
							position: "absolute",
							left: (mapWidth / 10) * i - 10,
							bottom: 5,
							color: "black",
							background: "white",
							fontSize: "12px",
						}}
					>
						{`${(i * (mapWidthMeters / 10)).toFixed(1)}m`}
					</div>,
				);
			}
		}

		setObjects([...mapGuides, ...tmpObjects]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		elementDimension, items, mapHeight, mapWidth, resolution, showMapGuides, inSimulation, useAutoItems, showAutoItems, autoWalls,
	]);

	useEffect(() => {
		(async () => {
			let conn;
			if (inSimulation && !connection) {
				conn = await connect({
					onConnect: () => { success("Connected to simulation server"); },
					onError: () => { error("There was an error trying to connect to websocket"); },
					onMessage: (msg) => { onMessage(msg); },
					type: "envpop",
				});
				setConnection(conn);

				connectToGeneralBroker({ connection: conn });
			}

			return async () => {
				if (conn) {
					await disconnect({ connection: conn });
				}
			};
		})();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success, error, inSimulation, connection]);

	useEffect(() => {
		if (connected && inSimulation) {
			subscribeToQueue(`streamsim/${jwt.decode().id}/notify`, null, connection);
		}
	}, [connected, inSimulation, connection]);

	useEffect(() => {
		setInSimulation(propsInSimulation);
	}, [propsInSimulation]);

	useImperativeHandle(ref, () => ({
		onResize() {
			changeMapDimensions();
		},
	}));

	return ([
		<Grid key="main" container className={classes.root}>
			<Grid item id="mainmap" xs={12} lg={10} className={classes.mapRoot}>
				<Grid item id="map" className={classes.map} sx={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
					{grid}
					{objects}
					{descriptionOpen && !dragging && (
						<Grid
							item
							width="100%"
							height="100%"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								backgroundColor: "rgba(0, 0, 0, 0.6)",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Typography textAlign="center" fontSize="20px" px={2} color="white!important">
								{descriptionTitle}
							</Typography>
							<Typography textAlign="center" fontSize="16px" px={2} mt={2} color="white!important">
								{descriptionBody}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
			{dragging && (
				<div className={classes.draggingTile} style={{ left: `${draggingPosition.x}px`, top: `${draggingPosition.y}px` }} />
			)}
			<Grid item xs={12} lg={2} className={classes.controlsRoot}>
				<Grid item className={classes.sliderBox}>
					<Grid item className={classes.optionsRow}>
						<Typography textAlign="left" fontSize="14px">{"Map Width (meters)"}</Typography>
						<Typography textAlign="right">{mapWidthMeters.toFixed(0)}</Typography>
					</Grid>
					<Slider
						color="secondary"
						value={mapWidthMeters}
						min={1}
						max={1000}
						step={1}
						disabled={inSimulation}
						onChange={(event) => {
							changeMapWidthMeters(event.target.value);
						}}
					/>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Use auto-walls"}</Typography>
					<Switch
						checked={useAutoItems}
						disabled={inSimulation}
						onChange={(ev) => changeUseAutoItems(ev)}
					/>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Show auto-walls"}</Typography>
					<Switch
						checked={showAutoItems}
						disabled={!useAutoItems || inSimulation}
						onChange={changeShowAutoItems}
					/>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Show guides"}</Typography>
					<Switch
						checked={showMapGuides}
						onChange={changeShowMapGuides}
					/>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					mb={0.5}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Temperature"}</Typography>
					<Grid width="80px">
						<CustomNumericInput
							id="temperature-input"
							width="100%"
							min={0}
							max={100}
							step={1}
							precision={0}
							format={(num) => `${num}°C`}
							parse={(num) => num.replace("°C", "")}
							disabled={inSimulation}
							value={temperature}
							onChange={changeTemperature}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					mb={0.5}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Humidity"}</Typography>
					<Grid width="80px">
						<CustomNumericInput
							id="humidity-input"
							width="100%"
							min={0}
							max={100}
							step={1}
							precision={0}
							format={(num) => `${num}%`}
							parse={(num) => num.replace("%", "")}
							disabled={inSimulation}
							value={humidity}
							onChange={changeHumidity}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					mb={0.5}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Luminosity"}</Typography>
					<Grid width="80px">
						<CustomNumericInput
							id="luminosity-input"
							width="100%"
							min={0}
							max={100}
							step={1}
							precision={0}
							format={(num) => `${num}%`}
							parse={(num) => num.replace("%", "")}
							disabled={inSimulation}
							value={luminosity}
							onChange={changeLuminosity}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					width="100%"
					className={classes.sliderBox}
					sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
				>
					<Typography textAlign="left" fontSize="14px">{"Ph"}</Typography>
					<Grid width="80px">
						<CustomNumericInput
							id="ph-input"
							width="100%"
							min={0}
							max={14}
							step={0.1}
							precision={1}
							disabled={inSimulation}
							value={ph}
							onChange={changePh}
						/>
					</Grid>
				</Grid>
				<Divider className={classes.divider} />
				{Object.keys(availableItems).map((cat) => (
					<Grid key={cat} width="100%" style={{ padding: "0px 20px", marginBottom: "10px" }}>
						<Accordion
							title={availableItems[cat].title}
							titleBackground="third"
							content={(
								<Grid
									container
									className={classes.tilesBox}
									onMouseOver={() => setDescriptionOpen(true)}
									onMouseOut={() => setDescriptionOpen(false)}
								>
									{Object.keys(availableItems[cat].items).map((comp) => (
										<Item
											key={comp}
											id={comp}
											mode="menu"
											icon={availableItems[cat].items[comp].icon}
											variables={availableItems[cat].items[comp].variables}
											icons={availableItems[cat].items[comp].icons}
											elementDimensions={elementDimension}
											mapWidth={mapWidth}
											mapHeight={mapHeight}
											cat={cat}
											itemCat={comp}
											createNewItem={inSimulation ? () => {} : createNewItem}
											tooltip={availableItems[cat].items[comp].tooltip}
											setDescriptionTitle={setDescriptionTitle}
											setDescriptionBody={setDescriptionBody}
											onDrag={inSimulation ? () => {} : onDrag}
											onDragStop={inSimulation ? () => {} : onDragStop}
										/>
									))}
								</Grid>
							)}
							alwaysExpanded={false}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>,
		<Spinner key="spinner" open={isLoading} />,
	]);
});

export default CreateMission;
