import { shortDescription } from "./smauto/short-description.js";
import { getDocumentation } from "./smauto/documentation.js";
import {
	MQTTExample,
	EntitySensorExample,
	EntityActuatorExample,
	AutomationExample,
	RTMonirotExample,
	MetadataExample,
} from "./smauto/insertables.js";

import SmautoLight from "../assets/dsls-logos/smauto_L.png";
import SmautoDark from "../assets/dsls-logos/smauto_D.png";

/**
 * Retrieves the constants for the SmAuto DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getSmautoConstants = async () => {
	const constants = {
		enabled: true,
		_id: "smauto",
		name: "SmAuto",
		short: "smauto",
		description: "SmAuto is a Domain Specific Language (DSL) that enables users to program complex automation scenarios, for connected IoT devices in smart environments, that go beyond simple tasks.",
		logoUrl: SmautoLight,
		logoUrlDark: SmautoDark,
		insertableEntities: {
			Broker: MQTTExample,
			SensorEntity: EntitySensorExample,
			ActuatorEntity: EntityActuatorExample,
			Automation: AutomationExample,
			RTMonitor: RTMonirotExample,
			Metadata: MetadataExample,
		},
		shortDescription,
		documentation: await getDocumentation(),
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: ["codintxt"],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		generations: ["Merged", "Virtual entities", "Automations"],
		generationsOutputMap: {
			Merged: "string",
			"Virtual entities": "string",
			Automations: "string",
		},
		generationLanguage: "python",
		supportsDeployment: false,
		keywords: [
			"Broker",
			"Metadata",
			"RTMonitor",
			"Entity",
			"Automation",
			"end",
		],
		llmSupport: false,
		tags: ["CPS", "Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};

