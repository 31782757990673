import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";

import Tooltip from "../../Tooltip.js";
import Popup from "../../Popup.js";
import { useSnackbar } from "../../../utils/index.js";
import Form from "../../Form.js";
import { ErrorBackgroundButton } from "../../Buttons.js";
import { Grid } from "@mui/material";

const toTitleCase = (phrase) => (phrase
	.toLowerCase()
	.split(" ")
	.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
	.join(" ")
);

const colors = [
	{ color: '#ff0000', title: 'Red' },
	{ color: '#ffffff', title: 'White' },
	{ color: '#00ff00', title: 'Green' },
	{ color: '#0000ff', title: 'Blue' },
	{ color: '#000000', title: 'Black' },
	{ color: '#ffff00', title: 'Yellow' },
	{ color: '#ff00ff', title: 'Magenta' },
	{ color: '#00ffff', title: 'Cyan' },
];

const componentToHex = (c) => {
	const hex = c.toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const hexToRgb = (hex) => {
	const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
	return result ? {
		r: Number.parseInt(result[1], 16),
		g: Number.parseInt(result[2], 16),
		b: Number.parseInt(result[3], 16),
	} : null;
};

const Item = ({
	mode = "view",
	id = "",
	name: propsName = "",
	initialName = "",
	position: propsPosition = { x: 0, y: 0 },
	icon = null,
	icons: propsIcons = {},
	variables: propsVariables = {},
	elementDimensions: propsElementDimensions = 30,
	mapWidth: propsMapWidth = 100,
	mapHeight: propsMapHeight = 80,
	resolution: propsResolution = 0.1,
	cat = "",
	itemCat = "",
	tooltip = { header: "", body: "" },
	updateItem: propsUpdateItem = () => {},
	deleteItem: propsDeleteItem = () => {},
	checkNameExists: propsCheckNameExists = () => {},
	createNewItem: propsCreateNewItem = () => {},
	setDescriptionTitle: propsSetDescriptionTitle = () => {},
	setDescriptionBody: propsSetDescriptionBody = () => {},
	onDrag: propsOnDrag = () => {},
	onDragStop: propsOnDragStop = () => {},
}) => {
	const [name, setName] = useState(propsName);
	const [position, setPosition] = useState(propsPosition);
	const [variables, setVariables] = useState(propsVariables);
	const [elementDimensions, setElementDimensions] = useState(propsElementDimensions);

	const [mapWidth, setMapWidth] = useState(propsMapWidth);
	const [mapHeight, setMapHeight] = useState(propsMapHeight);
	const [resolution, setResolution] = useState(propsResolution);

	const [dragging, setDragging] = useState(false);
	const [popupOpen, setPopupOpen] = useState(false);
	const [extraPopupOpen, setExtraPopupOpen] = useState(false);
	const [extraVariable, setExtraVariable] = useState(null);
	const [metersTooltipOpen, setMetersTooltipOpen] = useState(false);
	const [metersTooltipPosition, setMetersTooltipPosition] = useState({ x: 0, y: 0 });
	const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
	const [metersDisplay, setMetersDisplay] = useState(false);
	const [tmpFormValues, setTmpFormValues] = useState(Object.fromEntries(
		[...Object.keys(variables).map((variable) => [variable, variables[variable].value]), ["itemName", name]],
	));
	const [tmpFormExtraValues, setTmpFormExtraValues] = useState({});

	const isHuman = itemCat === "human";
	const isColor = itemCat === "color";
	const isRobot = itemCat === "robot";

	const { error } = useSnackbar();

	const sendUpdate = (newValues) => {
		const tmpState = {
			id,
			itemName: name,
			x: position.x,
			y: position.y,
			variables,
			cat,
			itemCat,
		};

		for (const [key, value] of Object.entries(newValues)) {
			tmpState[key] = value;
		}

		propsUpdateItem(cat, itemCat, id, tmpState);
	};

	const onClick = () => {
		setPopupOpen(true);
	};

	const onStart = (event) => {
		event.preventDefault();
		const xMeters = (((position.x / 100) * mapWidth) * resolution).toFixed(2);
		const yMeters = (((position.y / 100) * mapHeight) * resolution).toFixed(2);
		setMetersTooltipOpen(true);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		propsSetDescriptionTitle(null);
		propsSetDescriptionBody(null);
		setInfoTooltipOpen(false);
	};

	const onDrag = (event, data) => {
		event.preventDefault();
		const xMeters = ((((position.x / 100) * mapWidth) + data.x) * resolution).toFixed(2);
		const yMeters = ((((position.y / 100) * mapHeight) - data.y) * resolution).toFixed(2);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setDragging(true);
	};

	const onStop = (event, data) => {
		event.preventDefault();
		if (dragging) {
			const newX = Math.max(Math.min(((((position.x / 100) * mapWidth) + data.x) / mapWidth) * 100, 100), 0);
			const newY = Math.max(Math.min((((((position.y / 100) * mapHeight) - data.y) / mapHeight) * 100), 100), 0);
			sendUpdate({ x: newX, y: newY });
		} else {
			onClick();
		}

		setDragging(false);
		setMetersTooltipOpen(false);
	};

	const onMenuStart = (event) => {
		event.preventDefault();
		setDragging(true);
	};

	const onMenuDrag = (event) => {
		event.preventDefault();
		const { x, y } = event;
		propsOnDrag(x, y);
	};

	const onMenuStop = (event) => {
		event.preventDefault();
		propsOnDragStop();
		setDragging(false);

		const mapComponent = document.querySelector("#map");
		const { top, right, bottom, left } = mapComponent.getBoundingClientRect();
		const { x, y } = event;

		if (x >= left && x <= right && y >= top && y <= bottom) {
			const newX = ((x - left) / mapWidth) * 100;
			const newY = ((bottom - y) / mapHeight) * 100;
			propsCreateNewItem(cat, itemCat, newX, newY);
		}
	};

	const deleteItem = () => {
		propsDeleteItem(cat, itemCat, id);
	};

	const checkNameExists = (value) => {
		const { matchesPattern, found } = propsCheckNameExists(value, initialName, cat, itemCat, id);

		if (matchesPattern) {
			error("Name cannot follow the template category_number");
		} else if (found) {
			error("Name already exists");
		}

		return matchesPattern || found;
	};

	const onSettingsSubmit = (values) => {
		const { itemName, ...newValues } = values;

		if (itemName !== name && checkNameExists(itemName)) {
			return;
		}

		const newVariables = { ...variables };
		for (const [key, value] of Object.entries(newValues)) {
			newVariables[key].value = value;
		}

		sendUpdate({ variables: newVariables, itemName: itemName.toLowerCase().split(" ").join("_") });
		setPopupOpen(false);
	};

	const onExtraSettingsSubmit = (values) => {
		const { itemName, ...newValues } = tmpFormValues;

		if (itemName !== name && checkNameExists(itemName)) {
			return;
		}

		const newVariables = { ...variables };
		for (const [key, value] of Object.entries(newValues)) {
			newVariables[key].value = value;
		}

		const newExtraVariables = { ...variables[extraVariable].extra };
		for (const [key, value] of Object.entries(values)) {
			newExtraVariables[key].value = value;
		}

		newVariables[extraVariable].extra = newExtraVariables;

		sendUpdate({ variables: newVariables, itemName: itemName.toLowerCase().split(" ").join("_") });
		setExtraPopupOpen(false);
	};

	const changeRGB = (color) => {
		const { r, g, b } = hexToRgb(color.hex);
		const newVariables = { ...variables };
		newVariables.r.value = r;
		newVariables.g.value = g;
		newVariables.b.value = b;

		sendUpdate({ variables: newVariables });
	};

	const closePopup = () => {
		setPopupOpen(false);
		setTmpFormValues(Object.fromEntries(Object.keys(variables)
			.map((variable) => [variable, variables[variable].value])));
	};

	const closeExtraPopup = () => {
		setExtraPopupOpen(false);
		setTmpFormExtraValues(Object.fromEntries(Object.keys(variables[extraVariable].extra)
			.map((variable) => [variable, variables[extraVariable].extra[variable].value])));
	};

	const formContent = useMemo(() => {
		const content = [{
			customType: "input",
			id: "itemName",
			type: "text",
			label: "Name",
			placeholder: "Name",
			value: name,
			onChange: (event) => {
				checkNameExists(event.target.value);
				setTmpFormValues({ ...tmpFormValues, itemName: event.target.value });
			},
		}];

		if (!isColor) {
			for (const [variable, options] of Object.entries(variables)) {
				const {
					type,
					name: varName,
					placeholder,
					min,
					max,
					step,
					precision,
					format,
					options: selectItems,
					needs,
				} = options;
				if (needs) {
					let needsMet = true;
					for (const neededKey of Object.keys(needs)) {
						const neededValue = needs[neededKey];
						if (tmpFormValues?.[neededKey] !== undefined) {
							let found = false;
							for (const val of neededValue) {
								if (tmpFormValues[neededKey] === val) {
									found = true;
									break;
								}
							}

							needsMet = found;
							if (!needsMet) {
								break;
							}
						}
					}

					if (!needsMet) {
						// eslint-disable-next-line no-continue
						continue;
					}
				}

				switch (type) {
					case "text": {
						content.push({
							customType: "input",
							id: variable,
							type: "text",
							label: varName,
							placeholder,
							value: tmpFormValues[variable],
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
							},
						});

						break;
					}

					case "number": {
						content.push({
							customType: "number",
							id: variable,
							type: "number",
							label: varName,
							value: variables[variable].value,
							min: (typeof min === "string" ? tmpFormValues?.[min] : min),
							max: (typeof max === "string" ? tmpFormValues?.[max] : max),
							step,
							precision,
							...(format ? {
								format: (num) => `${num}${format}`,
							} : {}),
							...(format ? {
								parse: (num) => num.replace(format, ""),
							} : {}),
							onChange: (val) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: val });
							},
						});

						break;
					}

					case "boolean": {
						content.push({
							customType: "checkbox",
							id: variable,
							label: varName,
							defaultValue: tmpFormValues[variable],
							color: "secondary",
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.checked });
							},
						});

						break;
					}

					case "select": {
						content.push({
							customType: "dropdown",
							id: variable,
							label: varName,
							defaultValue: tmpFormValues[variable],
							items: selectItems.map((item) => ({ value: item, text: item })),
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
							},
						});

						break;
					}

					case "switch": {
						content.push({
							customType: "switch",
							id: variable,
							label: varName,
							defaultValue: tmpFormValues[variable],
							onChange: (event) => {
								setTmpFormValues({ ...tmpFormValues, [variable]: event.target.checked });
							},
						});

						break;
					}

					case "extra": {
						content.push({
							customType: "button",
							id: variable,
							text: varName,
							color: "secondary",
							onClick: () => {
								setExtraPopupOpen(true);
								setExtraVariable(variable);
								setTmpFormExtraValues(Object.fromEntries(Object.keys(variables[variable].extra)
									.map((variab) => [variab, variables[variable].extra[variab].value])));
								setPopupOpen(false);
							},
						});

						break;
					}

					default:
				// Do nothing
				}
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, variables, tmpFormValues]);

	const extraFormContent = useMemo(() => {
		const content = [];

		for (const [variable, options] of Object.entries(variables?.[extraVariable]?.extra ?? {})) {
			const {
				type,
				name: varName,
				placeholder,
				min,
				max,
				step,
				precision,
				format,
				options: selectItems,
				needs,
			} = options;
			if (needs) {
				let needsMet = true;
				for (const neededKey of Object.keys(needs)) {
					const neededValue = needs[neededKey];
					if (tmpFormExtraValues?.[neededKey] !== undefined) {
						let found = false;
						for (const val of neededValue) {
							if (tmpFormExtraValues[neededKey] === val) {
								found = true;
								break;
							}
						}

						needsMet = found;
						if (!needsMet) {
							break;
						}
					}
				}

				if (!needsMet) {
					// eslint-disable-next-line no-continue
					continue;
				}
			}

			switch (type) {
				case "text": {
					content.push({
						customType: "input",
						id: variable,
						type: "text",
						label: varName,
						placeholder,
						value: tmpFormExtraValues[variable],
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "number": {
					content.push({
						customType: "number",
						id: variable,
						type: "number",
						label: varName,
						value: variables[extraVariable].extra[variable].value,
						min: (typeof min === "string" ? tmpFormExtraValues?.[min] : min),
						max: (typeof max === "string" ? tmpFormExtraValues?.[max] : max),
						step,
						precision,
						...(format ? {
							format: (num) => `${num}${format}`,
						} : {}),
						...(format ? {
							parse: (num) => num.replace(format, ""),
						} : {}),
						onChange: (val) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: val });
						},
					});

					break;
				}

				case "boolean": {
					content.push({
						customType: "checkbox",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						color: "secondary",
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.checked });
						},
					});

					break;
				}

				case "select": {
					content.push({
						customType: "dropdown",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						items: selectItems.map((item) => ({ value: item, text: item })),
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "switch": {
					content.push({
						customType: "switch",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.checked });
						},
					});

					break;
				}

				default:
			// Do nothing
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, variables, tmpFormExtraValues, extraVariable]);

	useEffect(() => {
		sendUpdate({});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMapWidth(propsMapWidth);
	}, [propsMapWidth]);

	useEffect(() => {
		setMapHeight(propsMapHeight);
	}, [propsMapHeight]);

	useEffect(() => {
		if (propsPosition.x !== position.x || propsPosition.y !== position.y) {
			setPosition(propsPosition);
		}
	}, [position.x, position.y, propsPosition]);

	useEffect(() => {
		setVariables(propsVariables);
	}, [propsVariables]);

	useEffect(() => {
		setName(propsName);
	}, [propsName]);

	useEffect(() => {
		setResolution(propsResolution);
	}, [propsResolution]);

	useEffect(() => {
		setElementDimensions(propsElementDimensions);
	}, [propsElementDimensions]);

	const fov = variables.fov?.value || 0;
	const cameraRange = itemCat === "camera"
		? (fov < 180
			? `conic-gradient(transparent 0deg ${90 - fov / 2}deg, rgba(255, 0, 0, 0.4) ${90 - fov / 2}deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg 360deg)`
			: `conic-gradient(rgba(255, 0, 0, 0.4) 0deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg ${360 + (90 - fov / 2)}deg, rgba(255, 0, 0, 0.4) ${360 + (90 - fov / 2)}deg 360deg)`
		)
		: null;
	const robotScale = 4 / 5;

	return ([
		<Draggable
			key={`item_${id}`}
			allowAnyClick={false}
			{... (mode === "edit" ? { grid: [5, 5] } : {})}
			zIndex={100}
			position={{ x: 0, y: 0 }}
			disabled={mode === "view"}
			onStart={mode === "menu" ? onMenuStart : (mode === "edit" ? onStart : () => {})}
			onDrag={mode === "menu" ? onMenuDrag : (mode === "edit" ? onDrag : () => {})}
			onStop={mode === "menu" ? onMenuStop : (mode === "edit" ? onStop : () => {})}
		>

			<div
				id={`item_${id}`}
				style={{
					width: isRobot
						? `${(mode === "menu" ? 45 : elementDimensions) * robotScale * propsIcons[variables.skin.value].aspectRatio}px`
						: (mode === "menu"
							? ((isHuman || isColor) ? "33px" : "45px")
							: `${((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions}px`),
					height: isRobot
						? `${(mode === "menu" ? 45 : elementDimensions) * robotScale}px`
						: (mode === "menu"
							? ((isHuman || isColor) ? "33px" : "45px")
							: `${((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions}px`),
					borderRadius: `${((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions}px`,
					...(mode !== "menu" && {
						position: "absolute",
						left: `calc(${position.x}% - ${(elementDimensions * (isRobot ? robotScale * propsIcons[variables.skin.value].aspectRatio : 1)) / 2}px)`,
						bottom: `calc(${position.y}% - ${(elementDimensions * (isRobot ? robotScale : 1)) / 2}px)`,
					}),
					border: isColor ? "2px solid #134eda" : "none",
					margin: (isHuman || isColor) ? "6px" : "0px",
					padding: (isHuman || isColor) ? "5px" : "0px",
					boxShadow: (isHuman || isColor) ? "0px 0px 20px 5px rgba(0, 0, 0, 0.5)" : "none",
					background: isHuman
						? "#134eda"
						: (isColor
							? `rgb(${variables.r.value}, ${variables.g.value}, ${variables.b.value})`
							: "transparent"
						),
					cursor: mode === "menu"
						? "move"
						: (mode === "view"
							? "help"
							: dragging ? "move" : "pointer"),
					opacity: (dragging && mode === "menu") ? 0 : 1,
					transform: "translate(-50%, -50%)",
				}}
			>
				<Tooltip
					open={infoTooltipOpen}
					handleOpen={() => {
						if (dragging || popupOpen) {
							return;
						}

						if (mode !== "menu" && Object.keys(variables).filter((variable) => variables[variable].tooltipDisplay).length === 0) {
							return;
						}

						if (mode === "menu") {
							propsSetDescriptionTitle(tooltip.header);
							propsSetDescriptionBody(tooltip.body);
						} else {
							setInfoTooltipOpen(true);
						}
					}}
					handleClose={() => {
						propsSetDescriptionTitle(null);
						propsSetDescriptionBody(null);
						setInfoTooltipOpen(false);
					}}
					title={(
						mode === "menu"
							? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										textAlign: "center",
										width: "150px",
										padding: "5px",
										borderRadius: "10px",
										color: "white",
									}}
								>
									<div style={{ fontWeight: "bold", fontSize: "16px" }}>
										{tooltip.header}
									</div>
									<div
										style={{
											height: "fit-content",
											wordBreak: "break-word",
										}}
									>
										{tooltip.body}
									</div>
								</div>
							)
							: (
								<>
									<div
										key="itemTooltip_name"
										style={{
											display: "flex",
											flexDirection: "column",
											textAlign: "center",
											width: "150px",
											borderRadius: "10px",
											color: "white",
										}}
									>
										<div style={{ fontWeight: "bold", fontSize: "16px" }}>
											{name}
										</div>
									</div>
									{Object.keys(variables)
										.filter((variable) => variables[variable].tooltipDisplay)
										.map((variable) => (
											<div
												key={`itemTooltip_${variable}`}
												style={{
													display: "flex",
													flexDirection: "column",
													textAlign: "center",
													width: "150px",
													padding: "5px",
													borderRadius: "10px",
													color: "white",
												}}
											>
												<div style={{ fontWeight: "bold", fontSize: "16px" }}>
													{variables[variable].name}
												</div>
												<div
													style={{
														height: "fit-content",
														wordBreak: "break-word",
														fontSize: "14px",
													}}
												>
													{
														["switch", "boolean"].includes(variables[variable].type)
															? (variables[variable].value ? "On" : "Off")
															: variables[variable].value || "-"
													}
												</div>
											</div>
										))}
								</>
							)
					)}
					onMouseLeave={() => {
						propsSetDescriptionTitle(null);
						propsSetDescriptionBody(null);
						setInfoTooltipOpen(false);
					}}
				>
					<div style={{ ...(variables?.theta?.value !== undefined && { transform: `rotate(${-variables.theta.value}deg)` }) }}>
						<img
							id={`itemImg_${id}`}
							src={
								isHuman
									? propsIcons[`${variables.move.value ? "1" : "0"}_${variables.sound.value ? "1" : "0"}`]
									: (isRobot
										? propsIcons[`${variables.skin.value}`].image
										: icon)
							}
							alt=""
							draggable={false}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								zIndex: dragging ? 1000 : 1,
							}}
							onMouseOver={() => setMetersDisplay(true)}
							onMouseOut={() => setMetersDisplay(false)}
							onFocus={() => setMetersDisplay(true)}
							onBlur={() => setMetersDisplay(false)}
						/>
						{mode !== "menu" && metersDisplay && !dragging && (
							Object.keys(variables).map((variable) => (
								variables[variable].metersDisplay && (
									<div
										key={`meters_${variable}`}
										style={{
											width: `${((2 * variables[variable].value) / resolution)}px`,
											height: `${((2 * variables[variable].value) / resolution)}px`,
											borderRadius: `${((2 * variables[variable].value) / resolution)}px`,
											background: itemCat === "camera"
												? "transparent"
												: (cat === "general"
													? "rgba(19, 78, 218, 0.6)"
													: (cat === "effectors"
														? "rgba(250, 160, 0, 0.6)"
														: "rgba(255, 0, 0, 0.6)"
													)),
											backgroundImage: cameraRange,
											position: "absolute",
											top: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											left: `${-(variables[variable].value / resolution) + (((isHuman || isColor) ? (11 / 15) : 1) * elementDimensions) / 2}px`,
											filter: "blur(5px)",
											zIndex: -1,
											animation: "areaAlarmAnimation 1.2s linear infinite",
										}}
									/>
								)
							))
						)}
					</div>
				</Tooltip>
				{mode === "edit" && (
					<div
						style={{
							display: (metersTooltipOpen) ? "block" : "none",
							width: "max-content",
							paddingLeft: "5px",
							paddingRight: "5px",
							height: "20px",
							position: "absolute",
							top: `-${(elementDimensions / 2) + 5}px`,
							left: `${(elementDimensions / 2) - (145 / 2)}px`,
							fontSize: "14px",
							textAlign: "center",
							background: "#7a8585",
							borderRadius: "10px",
							color: "white",
							zIndex: 100,
						}}
					>
						{`x: ${metersTooltipPosition.x}m, y: ${metersTooltipPosition.y}m`}
					</div>
				)}
			</div>
		</Draggable>,
		<Popup
			key={`popup_${id}`}
			width="500px"
			open={popupOpen}
			title={`${toTitleCase(itemCat)} ${id} Settings`}
			onClose={closePopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				{isColor && (
					<SketchPicker
						disableAlpha
						presetColors={colors}
						color={rgbToHex(variables.r.value, variables.g.value, variables.b.value)}
						onChange={changeRGB}
					/>
				)}
				<Form key={JSON.stringify(formContent)} content={formContent} onSubmit={onSettingsSubmit} />
				<ErrorBackgroundButton
					title="Delete"
					width="300px"
					onClick={deleteItem}
				/>
			</Grid>
		</Popup>,
		<Popup
			key={`extra_popup_${id}`}
			width="500px"
			open={extraPopupOpen}
			title={`${toTitleCase(itemCat)} ${id} Extra Settings`}
			onClose={closeExtraPopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Form key={JSON.stringify(extraFormContent)} content={extraFormContent} onSubmit={onExtraSettingsSubmit} />
			</Grid>
		</Popup>,
	]);
};

export default Item;
