import { createSlice } from '@reduxjs/toolkit';

export const brokersSlice = createSlice({
	name: 'brokers',
	initialState: [],
	reducers: {
		addBroker: (state, broker) => {
			const dataToImport = {
				id: broker.payload.id,
				parameters: broker.payload,
			};
			state.push(dataToImport);
			// console.log("Added broker", dataToImport);
		},
		updateBroker: (state, data) => {
			// console.log("Updating broker", data.payload.id, data.payload);

			// Update the broker in the state based on id
			const index = state.findIndex((broker) => broker.id === data.payload.id);
			if (index === -1) {
				const dataToImport = {
					id: data.payload.id,
					parameters: data.payload,
				};
				state.push(dataToImport);
			} else {
				state[index].parameters = data.payload;
			}

			// Return the updated state
			return state;
		},
		deleteBroker: (state, data) => {
			// console.log("Deleting broker", data.payload);

			// Remove the broker from the state based on id
			const index = state.findIndex((broker) => broker.id === data.payload);
			if (index !== -1) {
				state.splice(index, 1);
			}

			// Return the updated state
			return state;
		},
		setBrokers: (state, data) => {
			// console.log("Setting brokers", data.payload);
			state = data.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addBroker, updateBroker, setBrokers, deleteBroker } = brokersSlice.actions;

export default brokersSlice.reducer;
