import { useState, useEffect, useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Button, Paper, Breadcrumbs, Box } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	ExpandMore,
	MoreVert as MoreIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Image } from "mui-image";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { jwt, capitalize } from "../utils/index.js";
import logo from "../assets/images/PW_Horizontal_colour.png";
import logoutIcon from "../assets/icons/logout.png";
import teamsIcon from "../assets/icons/teams.png";
import assignmentsIcon from "../assets/icons/assignments.png";
import publicationsIcon from "../assets/icons/publications.png";
import secretsIcon from "../assets/icons/secrets.png";
import marketplaceIcon from "../assets/icons/marketplace.png";

const useStyles = makeStyles((theme) => ({
	outer: {
		backgroundColor: theme.palette.primary.main,
	},
	grow: {
		flexGrow: 1,
		flexBasis: "auto",
		background: "linear-gradient(to bottom, white 0%, #BAC1C5 100%)",
		zIndex: 1200,
		height: "70px",
	},
	root: {
		height: "30px",
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(2),
		borderRadius: "0px",
		background: theme.palette.grey.main,
	},
	icon: {
		marginRight: 0.5,
		width: 20,
		height: 20,
	},
	expanded: {
		background: "transparent",
	},
	innerSmallAvatar: {
		color: theme.palette.common.black,
		fontSize: "inherit",
	},
	anchorOriginBottomRightCircular: {
		".MuiBadge-anchorOriginBottomRightCircular": {
			right: 0,
			bottom: 0,
		},
	},
	avatar: {
		width: "30px",
		height: "30px",
		background: "white",
	},
	iconButton: {
		padding: "3px 6px",
	},
	menuItemButton: {
		width: "100%",
		bgcolor: "grey.light",
		"&:hover": {
			bgcolor: "grey.dark",
		},
	},
	grey: {
		color: "grey.500",
	},
}));

const ButtonWithText = ({ text, icon, more, handler }) => (
	<Button sx={{
		height: "100%", width: "120px", display: "flex", flexDirection: "column", p: 1, mx: 1, '&:hover': {
			bgcolor: 'white !important',
		},
	}} onClick={(event) => handler(event)}>
		<Image src={icon} alt={text} fit="contain" sx={{
			p: 0, my: 0, height: "100%", maxWidth: "200px", '&:hover': {
				bgcolor: 'transparent !important',
			},
		}} />
		<Typography align="center" color="primary.main" fontSize="small" fontWeight="bold" display="flex" alignItems="center" sx={{
			textTransform: "capitalize", '&:hover': {
				bgcolor: 'transparent !important',
			},
		}}>
			{text}
			{more && <ExpandMore />}
		</Typography>
	</Button>
);

const Header = ({ isAuthenticated }) => {
	const classes = useStyles();

	const location = useLocation();
	const navigate = useNavigate();
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
	const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
	const closeAll = () => {
		handleMobileMenuClose();
	};

	const fetchData = useCallback(
		async () => { },
		[],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const CrumpLink = styled(Link)(({ theme }) => ({ display: "flex", color: theme.palette.primary.main }));

	const buttons = [
		{
			icon: marketplaceIcon,
			text: "Marketplace",
			handler: () => {
				closeAll();
				navigate("/marketplace");
			},
		},
		{
			icon: teamsIcon,
			text: "Collaboration",
			handler: () => {
				closeAll();
				navigate("/collaboration");
			},
		},
		{
			icon: publicationsIcon,
			text: "Projects",
			handler: () => {
				closeAll();
				navigate("/projects");
			},
		},
		{
			icon: assignmentsIcon,
			text: "DSLs",
			handler: () => {
				closeAll();
				navigate("/dsls");
			},
		},
		{
			icon: secretsIcon,
			text: "Secrets",
			handler: () => {
				closeAll();
				navigate("/secrets");
			},
		},
		{
			icon: logoutIcon,
			text: "Logout",
			handler: () => {
				closeAll();
				jwt.destroyToken();
				navigate("/");
			},
		},
	];

	const renderMobileMenu = (
		<Menu
			keepMounted
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{buttons.map((button) => (
				<MenuItem key={button.text} onClick={button.handler}>
					<Image src={button.icon} width="20px" />
					<p style={{ marginLeft: "5px" }}>{button.text}</p>
					{button.more && <ExpandMore />}
				</MenuItem>
			))}
		</Menu>
	);

	const pathnames = location.pathname.split("/").filter(Boolean);
	const crumps = [];
	crumps.push(
		<CrumpLink to="/">
			{"Home"}
		</CrumpLink>,
	);

	for (const [ind, path] of pathnames.entries()) {
		let text = capitalize(path);
		// eslint-disable-next-line no-continue
		if (path === "home") continue;
		if (path === "file-upload") {
			text = "File Upload";
		}

		crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{text}</CrumpLink>);
	}

	return (
		<Box className={classes.outer}>
			<AppBar id="header" position="static" className={classes.grow}>
				<Toolbar className="header-container">
					<Box component={Link} to="/">
						<Image src={logo} alt="Logo" sx={{ p: 0, my: 0, mx: 2, height: "100%", maxWidth: "200px" }} />
					</Box>
					<Box className={classes.grow} style={{ height: "100%" }} />
					{isAuthenticated
						&& (
							<>
								<Box sx={{ display: { xs: "none", sm: "none", md: "flex" }, height: "100%", py: 1, bgcolor: "transparent" }}>
									{buttons.map((button) => (
										<ButtonWithText
											key={button.text}
											icon={button.icon}
											text={button.text}
											handler={button.handler}
											more={button.more}
										/>
									))}
								</Box>
								<Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
									<IconButton color="primary" onClick={handleMobileMenuOpen}><MoreIcon /></IconButton>
								</Box>
							</>
						)}
				</Toolbar>
			</AppBar>
			{isAuthenticated
				&& (
					<Paper elevation={0} className={classes.root}>
						<Breadcrumbs
							className="header-container"
							fontSize="small"
							separator={<NavigateNextIcon fontSize="small" />}
						>
							{crumps.map((e) => <div key={`crump_${e.props.to}`}>{e}</div>)}
						</Breadcrumbs>
					</Paper>
				)}
			{isAuthenticated
				&& (
					renderMobileMenu
				)}
		</Box>
	);
};

export default memo(Header);
