import {
	Grid,
	Typography,
	Select,
	MenuItem,
} from "@mui/material";

const ParametersSelect = ({ param, parameters, setParameters, nodeData }) => (
	<Grid
		item
		container
		display="flex"
		direction="row"
		justifyContent="flex-start"
		alignItems="center"
		sx={{
			marginBottom: "5px",
			width: "100%",
		}}
	>
		<Typography sx={{ marginRight: "10px", width: "40%" }}>
			{param.label}
		</Typography>
		<Select
			variant="filled"
			sx={{
				width: "55%",
				color: "white !important",
			}}
			value={parameters.find((p) => p.id === param.id).value}
			onChange={(e) => {
				const newParams = parameters.map((p) => {
					if (p.id === param.id) {
						return {
							...p,
							value: e.target.value,
						};
					}
					return p;
				});
				setParameters(newParams);
			}}
			MenuProps={{
				PaperProps: {
					sx: {
						background: "white",
						"& .MuiMenuItem-root": {
							color: "black !important",
						},
					},
				},
			}}
		>
			{param.options.map((option) => (
				<MenuItem
					key={option.value}
					value={option.value}
				>
					{option.label}
				</MenuItem>
			))}
		</Select>
	</Grid>
);

export default ParametersSelect;
