import RosbridgemlLogo from "../assets/dsls-logos/rosbridgeml.png";

/**
 * Retrieves the constants for the rosbridgeml DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getRosbridgemlConstants = async () => {
	const constants = {
		enabled: false,
		_id: "rosbridgeml",
		name: "rosbridge-ml",
		short: "rosbridgeml",
		description: "ROS + ROS2 bridges for message brokers",
		logoUrl: RosbridgemlLogo,
		logoUrlDark: RosbridgemlLogo,
		insertableEntities: {},
		shortDescription: "",
		documentation: "",
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		generationLanguage: "",
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["Robotics", "Network", "Textual"],
		initiallyCollapsed: false,
	};
	return constants;
};
