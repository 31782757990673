import { memo, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";

import { validations } from "../utils/index.js";

// eslint-disable-next-line import/no-named-as-default
import Input from "./Input.js";
import { HighlightBackgroundButton, PrimaryBackgroundButton, SecondaryBackgroundButton } from "./Buttons.js";
import Dropdown from "./Dropdown.js";
import Checkbox from "./Checkbox.js";
import RadioButtons from "./RadioButtons.js";
import Slider from "./Slider.js";
import Switch from "./Switch.js";
import DatePicker from "./DatePicker.js";
// import Autocomplete from "./Autocomplete.js";
import { PrimaryBackgroundAutocomplete, PrimaryBorderAutocomplete } from "./Autocomplete.js";
import DynamicInputs from "./DynamicInputs.js";
import WatchField from "./WatchField.js";
import OptionsField from "./OptionsField.js";
import CustomNumericInput from "./NumericInput.js";

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%",
		display: "flex",
		justifyContent: "space-evenly",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
	},
	inputBox: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "column",
		textAlign: "left",
		justifyContent: "space-between",
		color: "white",
	},
	input: {
		color: "black",
		width: "100%",
		maxWidth: "300px",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		"&:hover": {
			opacity: 0.8,
		},
	},
	wideInput: {
		color: "black",
		width: "100%",
		maxWidth: "800px",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			opacity: 0.8,
		},
		margin: "10px",
	},
	numberBox: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		textAlign: "left",
		justifyContent: "space-between",
		color: "white",
	},
	dynamicInputs: {
		width: "100%",
		maxWidth: "800px",
		marginBottom: "10px",
		color: "white",
	},
	watchField: {
		width: "100%",
		maxWidth: "800px",
		marginBottom: "10px",
		color: "white",
	},
	optionsfield: {
		width: "100%",
		maxWidth: "800px",
		marginBottom: "10px",
		color: "white",
	},
	autocomplete: {
		width: "100%",
		maxWidth: "716px",
		marginTop: "10px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		color: "black",
		"&:hover": {
			opacity: 0.8,
		},
	},
	dropdown: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		color: "white",
	},
	checkboxBox: {
		width: "100%",
		maxWidth: "500px",
		marginBottom: "10px",
		display: "flex",
	},
	checkbox: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		color: "white",
	},
	wideCheckbox: {
		width: "100%",
		maxWidth: "800px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		color: "white",
	},
	radioBox: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "column",
		color: "white",
	},
	sliderBox: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "column",
		color: "white",
	},
	datepickerBox: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "column",
		color: "white",
	},
	switchBox: {
		width: "100%",
		maxWidth: "300px",
		marginTop: "10px",
		marginBottom: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "white",
	},
	buttonTitle: {
		color: "primary",
		letterSpacing: theme.spacing(0.1),
	},
	markLabel: {
		color: "white",
	},
	button: {
		width: "100%",
		maxWidth: "300px",
		marginBottom: "10px",
		color: "black",
	},
}));

const Form = forwardRef(({ disabled: dsb, content, validationSchema, onSubmit, onSubmitProps, toResetForm = true }, ref) => {
	const classes = useStyles();
	const [formContent, setFormContent] = useState(content);
	const [disabled, setDisabled] = useState(dsb);
	const formRef = useRef();

	const [visibleVariable, setVisibleVariable] = useState({});
	const [addCounter, setAddCounter] = useState({});

	const isVisible = (visibleCondition, visibleVariable) => {
		if (!visibleCondition) return true;

		const evaluateCondition = (condition) => {
			if (typeof condition === "string") {
				return visibleVariable === condition;
			}

			if (Array.isArray(condition)) {
				if (typeof visibleVariable === "string") {
					return condition.includes(visibleVariable);
				}

				return condition.every((cond) => (visibleVariable ? visibleVariable.includes(cond) : true));
			}

			if (typeof condition === "object") {
				return Object.keys(condition).every((key) => {
					if (Array.isArray(condition[key])) {
						return condition[key].includes(visibleVariable[key]);
					}

					return condition[key] === visibleVariable[key];
				});
			}

			return false;
		};

		if (Array.isArray(visibleCondition)) {
			return visibleCondition.every(evaluateCondition);
		}

		return evaluateCondition(visibleCondition);
	};

	useEffect(() => {
		// Initialize visibleVariable based on the content
		const newVisibleVariable = {};

		for (const item of content) {
			if (item.visible_condition) {
				for (const key of Object.keys(item.visible_condition)) {
					if (newVisibleVariable[key] === undefined && item.value !== undefined) {
						newVisibleVariable[key] = item.value;
					}
				}
			} else if (item.value !== undefined) {
				newVisibleVariable[item.id] = item.value;
			}
		}

		setVisibleVariable(newVisibleVariable);
	}, [content]);

	useEffect(() => {
		setFormContent(content);
	}, [content]);

	useEffect(() => {
		setDisabled(dsb);
	}, [dsb]);

	useImperativeHandle(ref, () => ({
		getFormValues() {
			return formRef.current.values;
		},
	}));

	return (
		<Formik
			enableReinitialize
			innerRef={formRef}
			initialValues={formContent.reduce(
				(a, v) => ((v.customType === "input"
					|| v.customType === "number"
					|| v.customType === "wideInput"
					|| v.customType === "watchField"
					|| v.customType === "optionsfield"
					|| v.customType === "dynamicInputs"
					|| v.customType === "hidden"
					|| v.customType === "autocomplete")
					? { ...a, [v.id]: v.value }
					: (v.customType === "dropdown"
						|| v.customType === "checkbox"
						|| v.customType === "radio"
						|| v.customType === "slider"
						|| v.customType === "switch")
						? { ...a, [v.id]: v.defaultValue }
						: v.customType === "date-picker"
							? { ...a, [v.id]: v.value || null }
							: a),
				{},
			)}
			validationSchema={validations?.[validationSchema] || null}
			validateOnChange={false}
			onSubmit={(...formikArgs) => {
				onSubmit(...formikArgs, onSubmitProps);
				const [, { resetForm, setSubmitting }] = formikArgs;
				if (toResetForm) resetForm();
				setSubmitting(false);
			}}
		>
			{(formikProps) => (
				<form className={classes.form} onSubmit={formikProps.handleSubmit}>
					{formContent.map((comp) => (
						<div key={comp.key || comp.id} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
							{(comp.customType === "input" || comp.customType === "wideInput")
								&& isVisible(comp.visible_condition, visibleVariable) && (
									<Grid item className={classes.inputBox}>
										<Typography>{comp.label}</Typography>
										<Input
											key={comp.id}
											id={comp.id}
											type={comp.type}
											multiline={comp.multiline}
											minRows={comp.minRows}
											maxRows={comp.maxRows}
											className={comp.customType === "input" ? classes.input : classes.wideInput}
											placeholder={comp.placeholder}
											variant="filled"
											color="secondary"
											InputProps={comp.inputProps}
											value={formikProps.values[comp.id]}
											error={Boolean(formikProps.errors[comp.id])}
											helperText={formikProps.errors[comp.id] || comp?.helperText}
											disabled={disabled || comp.disabled}
											onChange={(event) => {
												formikProps.handleChange(event);
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
									</Grid>
								)}
							{comp.customType === "number"
								&& (
									<Grid item className={classes.numberBox}>
										<Typography>{comp.label}</Typography>
										<CustomNumericInput
											key={comp.id}
											id={comp.id}
											disabled={disabled || comp.disabled}
											width="100px"
											value={formikProps.values[comp.id]}
											min={comp.min}
											max={comp.max}
											step={comp.step}
											precision={comp.precision}
											format={comp.format}
											parse={comp.parse}
											mobile={comp.mobile}
											onChange={(value) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value,
													},
												});
												if (comp.onChange) {
													comp.onChange(value);
												}
											}}
										/>
									</Grid>
								)}
							{comp.customType === "watchField" && (
								<Grid item className={classes.watchField}>
									<WatchField
										key={comp.id}
										label={comp.label}
										id={comp.id}
										type={comp.type}
										multiline={comp.multiline}
										minRows={comp.minRows}
										maxRows={comp.maxRows}
										className={comp.customType === "input"}
										placeholder={comp.placeholder}
										variant="filled"
										color="secondary"
										targetFields={comp.targetFields}
										formikProps={formikProps}
										error={Boolean(formikProps.errors[comp.id])}
										disabled={disabled || comp.disabled}
										onChange={(event) => {
											formikProps.handleChange(event);
											if (comp.onChange) {
												comp.onChange(event);
											}
										}}
									/>
								</Grid>
							)}
							{comp.customType === "optionsfield" && (
								<Grid item className={classes.optionsfield}>
									<OptionsField
										key={comp.id}
										label={comp.label}
										id={comp.id}
										options={comp.options}
										formikProps={formikProps}
										error={Boolean(formikProps.errors[comp.id])}
										onChange={(event) => {
											formikProps.handleChange(event);
											if (comp.onChange) {
												comp.onChange(event);
											}
										}}
									/>
								</Grid>
							)}
							{comp.customType === "dynamicInputs" && (
								<Grid item className={classes.dynamicInputs}>
									<DynamicInputs
										id={comp.id}
										types={comp.types}
										values={formikProps.values[comp.id]}
										options={comp.options}
										connectedLabels={comp.connectedLabels}
										label={comp.label}
										labels={comp.labels}
										validationFlag={comp.validationFlag}
										complexLabel={comp.complexLabel}
										affectCounter={comp.affectCounter}
										counter={addCounter}
										handleChange={(event, index, fieldName) => {
											const currentActions = formikProps.values[comp.id];
											const updatedActions = currentActions
												.map((value, idx) => (idx === index ? { ...value, [fieldName]: event.target.value } : value));

											formikProps.setFieldValue(comp.id, updatedActions);
										}}
										disabledFields={comp.disabledFields}
										flex={comp.flex}
										requiredLabels={comp.requiredLabels}
										onAddAction={() => {
											const currentValues = formikProps.values[comp.id];

											let newValue;

											if (comp.defaultValue) {
												const { key, prefix, triggerCounter } = comp.defaultValue;

												const counter = addCounter[triggerCounter] ?? currentValues.length ? currentValues.length : 0;

												newValue = {
													key,
													value: "",
													[key]: `${prefix}_${counter + 1}`,
													counter: counter + 1,
												};
												setAddCounter((prevCounters) => ({ ...prevCounters, [triggerCounter]: counter }));
											} else {
												newValue = { key: "", value: "" };
											}

											const updatedValues = [...currentValues, newValue];

											formikProps.setFieldValue(comp.id, updatedValues);
										}}
										onRemoveAction={(index) => {
											const currentValues = formikProps.values[comp.id];
											const updatedValues = currentValues.filter((_, idx) => idx !== index);
											if (comp.defaultValue) {
												const { key, prefix, triggerCounter } = comp.defaultValue;
												const counter = addCounter[triggerCounter] - 1;
												setAddCounter((prevCounters) => ({ ...prevCounters, [triggerCounter]: counter }));
												for (const [ind, item] of updatedValues.entries()) {
													item[key] = `${prefix}_${ind + 1}`;
												}
											}

											formikProps.setFieldValue(comp.id, updatedValues);
										}}
									/>
								</Grid>
							)}
							{comp.customType === "dropdown"
								&& (
									<Grid item className={classes.dropdown}>
										<Typography>{comp.label}</Typography>
										<Dropdown
											id={comp.id}
											items={comp.items}
											value={formikProps.values[comp.id]}
											disabled={disabled || comp.disabled}
											size="medium"
											width="150px"
											filled={false}
											background="secondary"
											onChange={(event) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value: event.target.value,
													},
												});
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
									</Grid>
								)}

							{comp.customType === "autocomplete" && isVisible(comp.visible_condition, visibleVariable) && (
								<Grid item className={classes.autocomplete}>
									{comp.filled && (
										<PrimaryBackgroundAutocomplete
											key={comp.id}
											id={comp.id}
											disabled={disabled || comp.disabled}
											placeholder={comp.placeholder}
											width="100%"
											allowCustomInput={comp.allowCustomInput}
											options={comp.options}
											label={comp.label}
											value={formikProps.values[comp.id]}
											onChange={(value) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value,
													},
												});
												if (comp.onChange) {
													comp.onChange(value);
												}
											}}
										/>
									)}
									{!comp.filled && (
										<PrimaryBorderAutocomplete
											key={comp.id}
											id={comp.id}
											disabled={disabled || comp.disabled}
											placeholder={comp.placeholder}
											width="100%"
											allowCustomInput={comp.allowCustomInput}
											options={comp.options}
											label={comp.label}
											value={formikProps.values[comp.id]}
											onChange={(value) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value,
													},
												});
												if (comp.onChange) {
													comp.onChange(value);
												}
											}}
										/>
									)}
									{Boolean(formikProps.errors[comp.id])
										&& (
											<Typography color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
										)}
								</Grid>
							)}
							{comp.customType === "checkbox"
								&& (
									<Grid container item className={classes.checkboxBox}>
										<Grid item className={classes.checkbox}>
											<Typography>{comp.label}</Typography>
											<Checkbox
												key={comp.id}
												id={comp.id}
												checked={formikProps.values[comp.id]}
												size={comp.size}
												color={comp.color}
												sx={{
													color: `${comp.color}.main`,
													"&.Mui-checked": {
														color: `${comp.color}.main`,
													},
												}}
												icon={comp.icon}
												checkedIcon={comp.checkedIcon}
												disabled={disabled || comp.disabled}
												onChange={(event) => {
													formikProps.handleChange({
														target: {
															name: comp.id,
															value: !formikProps.values[comp.id],
														},
													});
													if (comp.onChange) {
														comp.onChange(event);
													}
												}}
											/>
										</Grid>
										{Boolean(formikProps.errors[comp.id])
											&& (
												<Typography color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
											)}
									</Grid>
								)}
							{comp.customType === "radio"
								&& (
									<Grid key={comp.id} container item className={classes.radioBox}>
										<Typography textAlign="left">{comp.label}</Typography>
										<RadioButtons
											id={comp.label}
											value={formikProps.values[comp.id]}
											row={comp.row}
											color={comp.color}
											labelPlacement={comp.labelPlacement}
											disabled={disabled || comp.disabled}
											items={comp.items}
											onChange={(event) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value: event.target.value,
													},
												});
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
										{Boolean(formikProps.errors[comp.id])
											&& (
												<Typography textAlign="left" color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
											)}
									</Grid>
								)}
							{comp.customType === "slider"
								&& (
									<Grid key={comp.id} container item className={classes.sliderBox}>
										<Typography textAlign="left">{comp.label}</Typography>
										<Slider
											iconBefore={comp.iconBefore}
											iconAfter={comp.iconAfter}
											color={comp.color || "secondary"}
											value={formikProps.values[comp.id]}
											min={comp.min}
											max={comp.max}
											marks={comp.marks}
											step={comp.step}
											size={comp.size}
											track={comp.track}
											valueLabelDisplay={comp.displayLabel}
											disabled={disabled || comp.disabled}
											onChange={(event) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value: event.target.value,
													},
												});
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
										{Boolean(formikProps.errors[comp.id])
											&& (
												<Typography textAlign="left" color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
											)}
									</Grid>
								)}
							{comp.customType === "switch"
								&& (
									<Grid key={comp.id} container item className={classes.switchBox}>
										<Typography textAlign="left">{comp.label}</Typography>
										<Switch
											color={comp.color || "secondary"}
											checked={formikProps.values[comp.id]}
											size={comp.size}
											disabled={disabled || comp.disabled}
											onChange={(event) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value: !formikProps.values[comp.id],
													},
												});
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
										{Boolean(formikProps.errors[comp.id])
											&& (
												<Typography textAlign="left" color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
											)}
									</Grid>
								)}
							{comp.customType === "date-picker"
								&& (
									<Grid key={comp.id} container item className={classes.datepickerBox}>
										<Typography textAlign="left">{comp.label}</Typography>
										<DatePicker
											type={comp.type || "desktop"} // desktop, mobile, time, datetime
											value={formikProps.values[comp.id]}
											disabled={disabled || comp.disabled}
											label={comp.sublabel || ""}
											views={comp.views || ["day", "month", "year"]}
											background="secondary"
											color="white"
											onChange={(value) => {
												formikProps.handleChange({
													target: {
														name: comp.id,
														value,
													},
												});
												if (comp.onChange) {
													comp.onChange(event);
												}
											}}
										/>
										{Boolean(formikProps.errors[comp.id])
											&& (
												<Typography textAlign="left" color="error" fontSize="small">{formikProps.errors[comp.id]}</Typography>
											)}
									</Grid>
								)}
							{comp.customType === "button"
								&& (
									comp.color === "third"
										? (
											<HighlightBackgroundButton
												id={comp.id}
												type={comp.type}
												width={comp.width || "100%"}
												disabled={formikProps.isSubmitting || disabled}
												className={classes.button}
												title={comp.text}
												onClick={comp.onClick}
											/>
										)
										: (comp.color === "secondary"
											? (
												<SecondaryBackgroundButton
													id={comp.id}
													type={comp.type}
													width={comp.width || "100%"}
													disabled={formikProps.isSubmitting || disabled}
													className={classes.button}
													title={comp.text}
													onClick={comp.onClick}
												/>
											)
											: (
												<PrimaryBackgroundButton
													id={comp.id}
													type={comp.type}
													width={comp.width || "100%"}
													disabled={formikProps.isSubmitting || disabled}
													className={classes.button}
													title={comp.text}
													onClick={comp.onClick}
												/>
											)
										)
								)}
							{comp.customType === "hidden" && (
								<input type="hidden" id={comp.id} value={formikProps.values[comp.id]} />
							)}
						</div>
					))}
				</form>
			)}
		</Formik>
	);
});

export default memo(Form);
