import effectors from "./effectors.js";
import general from "./general.js";
import sensors from "./sensors.js";

const items = {
	general,
	effectors,
	sensors,
};

export default items;
