import humidifierIcon from "../../../assets/images/missionItems/effectors/humidifier.png";
import lightIcon from "../../../assets/images/missionItems/effectors/light.png";
import relayIcon from "../../../assets/images/missionItems/effectors/relay.png";
import speakerIcon from "../../../assets/images/missionItems/effectors/speaker.png";
import thermostatIcon from "../../../assets/images/missionItems/effectors/thermostat.png";

const effectors = {
	title: "Effectors",
	items: {
		humidifier: {
			icon: humidifierIcon,
			variables: {
				humidity: {
					type: "number",
					name: "Humidity",
					value: 50,
					min: 0,
					max: 100,
					step: 1,
					precision: 0,
					format: "%",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
			},
			tooltip: {
				header: "Humidifier",
				body: "A humidifier can be used to change the humidity in the area it is placed",
			},
		},
		light: {
			icon: lightIcon,
			variables: {
				luminosity: {
					type: "number",
					name: "Luminosity",
					value: 50,
					min: 0,
					max: 100,
					step: 1,
					precision: 0,
					format: "%",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
			},
			tooltip: {
				header: "Light",
				body: "A programmable, smart light that can be turned on and off, defining its luminosity",
			},
		},
		relay: {
			icon: relayIcon,
			variables: {
				active: {
					type: "switch",
					name: "Active",
					value: false,
					tooltipDisplay: true,
					metersDisplay: false,
				},
			},
			tooltip: {
				header: "Switch",
				body: "A mechanical switch that can take various states",
			},
		},
		speaker: {
			icon: speakerIcon,
			variables: {},
			tooltip: {
				header: "Speaker",
				body: "A speaker can be used to “say” things or play sounds",
			},
		},
		thermostat: {
			icon: thermostatIcon,
			variables: {
				temperature: {
					type: "number",
					name: "Temperature",
					value: 20,
					min: 0,
					max: 200,
					step: 1,
					precision: 0,
					format: "°C",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
			},
			tooltip: {
				header: "Thermostat",
				body: "A thermostat can be used to change the temperature in the area it is placed",
			},
		},
	},
};

export default effectors;
