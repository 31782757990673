import city from "./city.js";
import farm from "./farm.js";
import blueprint from "./blueprint.js";
import home from "./home.js";
import superMarket from "./super-market.js";

const categories = {
	home,
	blueprint,
	city,
	farm,
	superMarket,
};

export default categories;
